angular
    .module('annexaApp')
    .component('annexaBoxObjectSessionExecutedOrder',{
        templateUrl: './components/sec/annexa-box-object-session-executed-order/annexa-box-object-session-executed-order.html',
        require: {
            sessionComponent: '^^annexaBoxObjectSession'
        },
        controller: ['$q', 'SecFactory', '$filter', '$rootScope', 'ErrorFactory', '$state', '$scope', 'RestService', 'HelperService', 'AnnexaPermissionsFactory', 'GlobalDataFactory', 'Language', 'AnnexaFormlyFactory', 'CommonService', 'DialogsFactory', 'HeaderService', 'SecModals', '$http', 'AnnexaModalFactory','NotificationFactory', 'DccumentsFactory', 
        	function ($q, SecFactory, $filter, $rootScope, ErrorFactory, $state, $scope, RestService, HelperService, AnnexaPermissionsFactory, GlobalDataFactory, Language, AnnexaFormlyFactory, CommonService, DialogsFactory, HeaderService, SecModals, $http, AnnexaModalFactory, NotificationFactory, DccumentsFactory) {
        	//region General
            var vm = this;
            vm.languageColumn = Language.getActiveColumn();

            vm.savePoint = function(modal) {
                var self = this;
                var orders = [];
                var addToOrder = (vm.sessionComponent.session[modal.extra.sessionProperty] && vm.sessionComponent.session[modal.extra.sessionProperty].length) ?
                		vm.sessionComponent.session[modal.extra.sessionProperty].length : 0;

                var proposals = modal.annexaFormly.fields[1].fieldGroup[0].data.proposals;
                var title = modal.annexaFormly.fields[1].fieldGroup[0].data.pointTitle;

                if(proposals && proposals.length > 0) {
                    _.forEach(proposals, function (value, key) {
                        if(value.selected) {
                        	var point = {
                                    session: { id: vm.sessionComponent.session.id },
                                    type: { id: modal.annexaFormly.model.row1.type },
                                    observations: '',
                                    proposal: { id: value.id },
                                    orderView: (orders.length + 1) + addToOrder,
                                    documents: value.documents
                                };
                        	var type = $linq(SecFactory.session.organ.agendaPointTypes).firstOrDefault(undefined,"x => x.id == "+point.type.id);
                        	if(_.contains(['VOTE'], ((type)?type.type:''))){
                        		point.requiredVoteState = value.requiredVoteState;
                        		point.votationType = value.votationType;
                    		}else if(_.contains(['PROPOSAL', 'OPINION'], ((type)?type.type:'')) && type && type.vote){
                    			point.requiredVoteState = value.requiredVoteState;
                        		point.votationType = value.votationType;
                        	}
                        	if(modal.extra.point){
                        		if(modal.extra.sameLevel == true){
                        			if(modal.extra.point.parent && modal.extra.point.parent.id){
                        				point.parent = {id:modal.extra.point.parent.id};
                        			}
                        		}else if(modal.extra.sameLevel == false){
                        			point.parent = {id:modal.extra.point.id};
                        		}
                        	}
                        	orders.push(point);
                        }
                    });
                } else if(title) {
                	var point = {
                            session: { id: vm.sessionComponent.session.id },
                            type: { id: modal.annexaFormly.model.row1.type },
                            observations: modal.annexaFormly.fields[1].fieldGroup[0].data.pointObservations,
                            title: modal.annexaFormly.fields[1].fieldGroup[0].data.pointTitle,
                            orderView: 1 + addToOrder,
                            documents: modal.annexaFormly.fields[1].fieldGroup[0].data.documents
                        };
                	var type = $linq(SecFactory.session.organ.agendaPointTypes).firstOrDefault(undefined,"x => x.id == "+point.type.id);
                	if(_.contains(['VOTE'], ((type)?type.type:''))){
                		point.requiredVoteState = modal.annexaFormly.fields[1].fieldGroup[0].data.requiredVoteState;
                		point.votationType = modal.annexaFormly.fields[1].fieldGroup[0].data.votationType;
                	}else if(_.contains(['PROPOSAL', 'OPINION'], ((type)?type.type:'')) && type && type.vote){
            			point.requiredVoteState = value.requiredVoteState;
                		point.votationType = value.votationType;
                	}
                	if(modal.extra.point){
                		if(modal.extra.sameLevel == true){
                			if(modal.extra.point.parent && modal.extra.point.parent.id){
                				point.parent = {id:modal.extra.point.parent.id};
                			}
                		}else if(modal.extra.sameLevel == false){
                			point.parent = {id:modal.extra.point.id};
                		}
                	}
                	orders.push(point);
                }

                if(orders && orders.length > 0) {
                    $http({
                        url: modal.extra.apiBaseUrl + '/list',
                        method: 'POST',
                        data: JSOG.encode(orders)
                    }).then(function (data) {
                    	var acAux = vm.sessionComponent.session.archiveClassificationAux;
                        vm.sessionComponent.session = JSOG.decode(data.data);
                        vm.sessionComponent.session.archiveClassificationAux = acAux;
                    	$rootScope.$broadcast('sessionPointOrderAdded', { origin: modal.extra.sessionProperty })
                    	$rootScope.$broadcast('updateSessionBoxes', {})
                        self.close();
                    }).catch(function (error) {
                        if(error && error.data && error.data.exception && error.data.exception.toUpperCase().endsWith('XDocReportException'.toUpperCase())){
							if(!modal.alerts){
								modal.alerts = [];
							}
							modal.alerts.push({msg: 'global.tram.errors.processTemplate', msgAux:((error.data.message)?error.data.message : undefined), show:function(alert){if(alert){alert.showAux = ((alert.showAux)?false:true)}}, showAux:false});
						}
                    });
                }
            }
            
            vm.seePoint = function(point, modalAux){
            	if(point && point.$nodeScope && point.$nodeScope.$modelValue && point.$nodeScope.$modelValue.executedOrder && point.$nodeScope.$modelValue.executedOrder.id){
		        	RestService.findOne('SessionExecutedOrder', point.$nodeScope.$modelValue.executedOrder.id)
		                .then(function(data) {
		                    var decodedData = JSOG.decode(data.data);
		                    var numAttendeeTotalVote = $linq(vm.sessionComponent.session.attendees).count("x => x.vote");
		                    var numAttendeeAbssentVote = $linq(vm.sessionComponent.session.attendees).count("x => x.vote && (x.attendeeType == 'EXCUSE_ABSENCE' || x.attendeeType == 'NOT_ASSISTS')");
                            var attendeesAbssentVote = $linq(vm.sessionComponent.session.attendees).where("x => x.vote && (x.attendeeType == 'EXCUSE_ABSENCE' || x.attendeeType == 'NOT_ASSISTS')").toArray();
                            
		                    var modal = angular.copy(SecModals.sessionPointEdit);
		                    if(decodedData.type.type == 'GROUP'){
			                	modal.title = "global.sec.literals.editGroup";
			                }
		                    modal.data = decodedData;
		                    modal.showVote = false;
		                    if(modal.data && modal.data.voteState && modal.data.voteState != 'REFUSED' && modal.data.voteState != 'POSTPONED' && modal.data.type && modal.data.type.type && ( _.contains(['VOTE'], modal.data.type.type) || ( _.contains(['OPINION', 'PROPOSAL'], modal.data.type.type) && modal.data.type.vote)) && !_.contains(['PENDING', 'CONVENED'], vm.sessionComponent.session.state)){
		                		modal.showVote = true;
		                    }
		                    modal.showVoteType = false;
		                    if(modal.data && modal.data.type && modal.data.type.type && ( _.contains(['VOTE'], modal.data.type.type) || ( _.contains(['OPINION', 'PROPOSAL'], modal.data.type.type) && modal.data.type.vote))){
		                		modal.showVoteType = true;
		                    }
		                    modal.extra = {maxVotes: numAttendeeTotalVote, activeTab: ((modal.showVote && modal.data.voteState && modal.data.voteState != 'NO_VOTE')? 1:0)};
		                    modal.alerts = [];
		                    modal.languageColumn = Language.getActiveColumn();
		                    modal.languageColumnEnum = "name";
		                    modal.canEdit = _.contains(['CONVENED', 'STARTED'], vm.sessionComponent.session.state);
		                    modal.requiredVoteStates = SecFactory.requiredVoteStates;
		                    modal.requiredVoteState = $linq(modal.requiredVoteStates).firstOrDefault(undefined,"x => x.id == '"+modal.data.requiredVoteState+"'");
		                    modal.requiredVoteStatesModify = SecFactory.requiredVoteStatesModify;
		                    modal.voteState = $linq(modal.requiredVoteStatesModify).firstOrDefault(undefined,"x => x.id == '"+modal.data.voteState+"'");
		                    modal.voteStateUpdated = $linq(modal.requiredVoteStatesModify).firstOrDefault(undefined,"x => x.id == '"+modal.data.voteStateUpdated+"'");
	                    	
		                    modal.votationTypes = SecFactory.votationTypes;
		                    modal.votationType = $linq(modal.votationTypes).firstOrDefault(undefined,"x => x.id == '"+modal.data.votationType+"'");
		                    modal.canModifyResult = false;
		                    if(modal.data.voteState != 'NO_VOTE'){
		                    	modal.canModifyResult = true;
		                    	modal.voteStateLiteral = "global.sec.literals."+modal.data.voteState;
		                    }
		                    modal.printEnum = function(value){
		                    	if(value && value.name){
		                    		return $filter('translate')(value.name);
		                    	}else{
		                    		return '';
		                    	}
		                    }
		                    if(modal.data.voteState == 'NO_VOTE'){
			                    if(modal.data && modal.data.votes){
			                    	_.forEach(modal.data.votes, function(vote){
			                    		if(vote.attendee && vote.attendee.id && attendeesAbssentVote && attendeesAbssentVote.length > 0){
			                    			if(_.contains($linq(attendeesAbssentVote).select("x => x.id").toArray(), vote.attendee.id)){
			                    				vote.vote = 'MISSING';
			                    			}
			                    		}
			                    	});
			                    }
		                    	modal.data.missingVotes = numAttendeeAbssentVote;
		                    }
		                    modal.updatePoint = function (val, prop, modelAux) {
		                        var model = ((prop)?this.data:modelAux);
		                        if(prop == "votationType" || prop == "requiredVoteState" || prop == "voteStateUpdated"){
	                        		model[prop] = val.id;
		                        }else if(prop){
	                        		model[prop] = val;
		                        }
		                        RestService.update('./api/sec/session_executed_order/' + model.id, model)
		                            .then(function (data) {
		                                var idExecutedOrder = $linq(vm.sessionComponent.session.executedOrder).indexOf("x => x.id == " + model.id);
		                                if(idExecutedOrder != -1) {
		                                	if(prop){
		                                		if(prop == "votationType" || prop == "requiredVoteState"){
		                                			vm.sessionComponent.session.executedOrder[idExecutedOrder][prop] = val.id;
		        		                        }else if(prop == "voteState"){
		        		                        	vm.sessionComponent.session.executedOrder[idExecutedOrder][prop] = val.id;
		        		                        	vm.sessionComponent.session.executedOrder[idExecutedOrder].changeStateMotive = model.changeStateMotive;
		        		                        }else{
		        		                        	vm.sessionComponent.session.executedOrder[idExecutedOrder][prop] = val;
		        		                        }
		                                	}else{
		                                		if(vm.sessionComponent.session.executedOrder[idExecutedOrder] && vm.sessionComponent.session.executedOrder[idExecutedOrder].documents){
			                                		vm.sessionComponent.session.executedOrder[idExecutedOrder].documents.length = 0;
			                                		if(data.documents){
			                                			_.forEach(data.documents, function(doc){
			                                				vm.sessionComponent.session.executedOrder[idExecutedOrder].documents.push(doc);
			                                			});
			                                			
			                                		}
		                                		}else{
		                                			vm.sessionComponent.session.executedOrder[idExecutedOrder].documents = [];
			                                		if(data.documents){
			                                			_.forEach(data.documents, function(doc){
			                                				vm.sessionComponent.session.executedOrder[idExecutedOrder].documents.push(doc);
			                                			});
			                                		}
		                                		}
		                                		if(modelAux){
	                                				if(modelAux.documents){
	                                					modelAux.documents.length = 0;
	                                				}else{
	                                					modelAux.documents = [];
	                                				}
	                                				if(data.documents && data.documents.length > 0){
		                                				_.forEach(data.documents, function(doc){
		                                					modelAux.documents.push(doc);
			                                			});
	                                				}
	                                			}
		                                	}
		                                }
		                                $rootScope.$broadcast('sessionPointOrderUpdated', {origin: 'executedOrder'})
		                            }).catch(function (error) {
		                            //Empty
		                        });
		                    }
		                    modal.validationCircuitObservations = function(){
		                    	var proposal = this.data.proposal;
		                    	if(proposal) {
		            				var dataModal = {
		        	                    row: true,
		        	                    colClass: 'col-sm-12',
		        	                    labelClass: ''
		        	                };
		        	                var modal = {
		        	                    title: 'global.literals.validationCircuitObservations',
		        	                    size: 'modal-md',
		        	                    icon: '',
		        	                    submitModal: function () {
		        	                    },
		        	                    alerts: []		
		        	                }
		        	                modal.annexaFormly = new AnnexaFormly();
		        	                
		        	                var fields = [];
		        	                if(proposal.reviewObservations) {
		        	                	fields.push(modal.annexaFormly.createField('reviewObservations', 'annexaLabelRow', 'col-sm-12',new AnnexaFormlyFieldLabelTemplateOptions('global.literals.validationCircuitReviewObservations',  proposal.reviewObservations),dataModal));
		        	                }
		        	                if(proposal.validateObservations) {
		        	                	fields.push(modal.annexaFormly.createField('reviewObservations', 'annexaLabelRow', 'col-sm-12',new AnnexaFormlyFieldLabelTemplateOptions('global.literals.validationCircuitValidateObservations',  proposal.validateObservations),dataModal));
		        	                }
		        	                if(proposal.acceptObservations) {
		        	                	fields.push(modal.annexaFormly.createField('reviewObservations', 'annexaLabelRow', 'col-sm-12',new AnnexaFormlyFieldLabelTemplateOptions('global.literals.validationCircuitAcceptObservations',  proposal.acceptObservations),dataModal));
		        	                }
		        	                modal.annexaFormly.addGroup('modal_body', 'modal-body p-lg', fields);
		        	                modal.annexaFormly.options = {};
		        	                modal.annexaFormly.options.formState = {readOnly: false};
		        	                AnnexaFormlyFactory.showModal("validationCircuitObservationsModal", modal, undefined, false, true); 
		                    	}
		        			}
		                    if(modal.data && modal.data.agreementDocument && modal.data.type && modal.data.type.type != 'GROUP'){
		                    	if(vm.sessionComponent.session.state ==  "STARTED" || vm.sessionComponent.session.state ==  "CONVENED") {
		                            modal.extraSubmitLabel2 = 'global.sec.literals.editAgrementDocument';
		                            modal.extraSubmitFunction2 = function() {
		                            	if(modal.data.agreementDocument){
		                            		DccumentsFactory.getEditOnlineUrl(modal.data.agreementDocument).then(function(data) {
		                                        window.location.href = data;
		                                    }).catch(function(error) {
		                                    	DialogsFactory.error('global.sec.literals.noAgreementDocument', 'Error');
		                                    });
		                            	}else{
		                            		DialogsFactory.error('global.sec.literals.noAgreementDocument', 'Error');
		                            	}
		                            }
		                    	}else if(vm.sessionComponent.session.realStartSessionDate){
		                    		modal.extraSubmitLabel2 = 'global.sec.literals.download';
		                            modal.extraSubmitFunction2 = function() {
	                            	    $rootScope.loading(true);

	                                    $http.get("./api/repodocs/downloadAlfDoc?id="+modal.data.agreementDocument, {responseType: 'arraybuffer'})
	                                        .success(function (data, status, headers) {
	                                            var retDownload = {
	                                                file: undefined,
	                                                fileName: undefined,
	                                                linkElement: undefined,
	                                                clickEvent: undefined
	                                            }
	                                            headers = headers();

	                                            var contentType = headers['content-type'];
	                                            retDownload.file = new Blob([data], {type: contentType});

	                                            if(modal.data.title){
	                                            	retDownload.fileName = modal.data.id+"_"+modal.data.title.replace(/\s/g,"");
	                                            }else{
	                                            	retDownload.fileName = modal.data.id;
	                                            }
	                                            retDownload.fileName += '.docx';
	                                            

	                                            try {
	                                                if (window.navigator.msSaveOrOpenBlob) {
	                                                    window.navigator.msSaveOrOpenBlob(retDownload.file, retDownload.fileName);
	                                                } else {
	                                                    retDownload.linkElement = document.createElement('a');
	                                                    var url = window.URL.createObjectURL(retDownload.file);
	                                                    retDownload.linkElement.setAttribute('href', url);
	                                                    retDownload.linkElement.setAttribute("download", retDownload.fileName);
	                                                    retDownload.clickEvent = new MouseEvent("click", {
	                                                        "view": window,
	                                                        "bubbles": true,
	                                                        "cancelable": false
	                                                    });
	                                                    retDownload.linkElement.dispatchEvent(retDownload.clickEvent);
	                                                }
	                                                $rootScope.loading(false);
	                                            } catch (e) {
	                                                $rootScope.loading(false);
	                                            }
	                                        }).error(function (error) {
	                                            $rootScope.loading(false);
	                                    });
		                            }
		                    	}
		                    }
	                    	if(vm.sessionComponent.session.state ==  "STARTED" && modal.showVote && modal.canEdit) {
	                            modal.extraSubmitLabel = 'global.sec.literals.calculateResult';
	                            modal.extraSubmitFunction = function() {
	                                var self = this;
	                                var idxExecutedOrder = $linq(vm.sessionComponent.session.executedOrder).indexOf("x => x.id == " + this.data.id);
	                                if(idxExecutedOrder != -1) {
	                                	var existNormalVote = $linq(vm.sessionComponent.session.executedOrder[idxExecutedOrder].votes).count("x => x.type == 'ORDINARY'") != 0;
	                                    var calculateNeededVotesToAprove = function(numVotes, numAttendeeVoted, numAttendeeVotedNoAbstention){
	                                    	var neededVotes = 0;
	                                    	if(self.data.requiredVoteState == 'APPROVED_SIMPLE_MAJORITY'){
	                                    		if(numAttendeeVotedNoAbstention > 0){
	                                    			neededVotes = Math.ceil(numAttendeeVotedNoAbstention/2);
	                                    		}else{
	                                    			neededVotes = -1;
	                                    		}
	                                    	}else if(self.data.requiredVoteState == 'APPROVED_ABSOLUTE_MAJORITY'){
	                                    		if(numVotes > 0){
	                                    			neededVotes = Math.ceil(numVotes/2);
	                                    		}else{
	                                    			neededVotes = -1;
	                                    		}
	                                    	}else if(self.data.requiredVoteState == 'APPROVED_QUALIFIED_TWO_THIRDS'){
	                                    		if(numVotes > 0){
	                                    			var twoThids = Math.ceil((numAttendeeVoted*2)/3);
	                                    			var absolute = Math.ceil(numVotes/2);
	                                    			neededVotes = ((twoThids > absolute)?twoThids:absolute);
	                                    		}else{
	                                    			neededVotes = -1;
	                                    		}
	                                    	}else{
	                                    		//Si no esta informat agafem que sigui la simple.
	                                    		if(numAttendeeVotedNoAbstention > 0){
	                                    			neededVotes = Math.ceil(numAttendeeVotedNoAbstention/2);
	                                    		}else{
	                                    			neededVotes = -1;
	                                    		}
	                                    	}
	                                    	return neededVotes;
	                                    }
	                                    var getVoteState = function(numVotes, yesVotes, noVotes, abstentionVotes, noPresentVotes) {
	                                    	var numAttendeeVotedNoAbstentionts = numVotes - noPresentVotes - abstentionVotes;
	                                    	var numAttendeeVoted = numVotes - noPresentVotes;
	                                    	var neededVotesToAprove = calculateNeededVotesToAprove(numVotes,numAttendeeVoted,numAttendeeVotedNoAbstentionts);
	                                    	
	                                    	if(abstentionVotes > 0 && abstentionVotes == numAttendeeVoted){
	                                        	 return 'REFUSED';
	                                        } else if(noPresentVotes > 0 && noPresentVotes == numVotes){
	                                        	 return 'REFUSED';
	                                        } else if((abstentionVotes + noPresentVotes) > 0 && (abstentionVotes + noPresentVotes) == numVotes){
	                                        	 return 'REFUSED';
	                                        } else if (numVotes != numAttendeeTotalVote || neededVotesToAprove < 0) {
	                                            return 'NO_VOTE';
	                                        } else  {
	                                            if (noVotes > yesVotes) {
	                                                return 'NOT_APPROVED';
	                                            } else if (yesVotes < neededVotesToAprove){
	                                            	return 'NOT_APPROVED';
	                                            } else if (noVotes == 0 && abstentionVotes == 0 && yesVotes == numVotes ) {
	                                            	return 'APPROVED';
	                                            } else if (noVotes == 0 && abstentionVotes == 0 && yesVotes == numAttendeeVoted ) {
	                                            	return 'APPROVED';
	                                            } else if (yesVotes >= Math.ceil((numAttendeeVoted*2)/3) && yesVotes >= Math.ceil(numVotes/2)) {
	                                            	return 'APPROVED';
	                                            } else if (yesVotes >= Math.ceil((numVotes*3)/5)) {
	                                            	return 'APPROVED';
	                                            } else if (yesVotes >= Math.ceil(numVotes/2)) {
	                                            	return 'APPROVED';
	                                            } else if (yesVotes >= Math.ceil(numAttendeeVotedNoAbstentionts/2)) {
                                            		return 'APPROVED'
	                                            } else {
	                                                return 'NOT_APPROVED';
	                                            }
	                                        }
	                                    }
	                                	_.forEach(vm.sessionComponent.session.executedOrder[idxExecutedOrder].votes, function (vote, index) {
	                                        var idxVote = $linq(self.data.votes).indexOf("x => x.id == " + vote.id);
	                                        if (idxVote != -1) {
	                                            vm.sessionComponent.session.executedOrder[idxExecutedOrder].votes[index].vote = self.data.votes[idxVote].vote;
	                                        }
	                                    });
	                                	var numVotesNormal = 0;
	                                	var numVotesTotalAux = 0;
	                                	if(existNormalVote){
		                                	if(self.data.votationType != 'NOMINAL'){
		                                		numVotesTotalAux = self.data.yesVotes + self.data.noVotes + self.data.abstentionVotes + self.data.missingVotes;
		                                	}else{
		                                		numVotesTotalAux = $linq(vm.sessionComponent.session.executedOrder[idxExecutedOrder].votes).count("x =>x.vote != 'NO_VOTE' && x.type == 'ORDINARY'");
		                                	}
	                                	}
	                                	if(numVotesTotalAux != numAttendeeTotalVote){
                                        	DialogsFactory.error($filter('translate')('global.sec.literals.numVotesIsNotcorrect'));
                                        }else{
		                                	if(self.data && self.data.votationType == 'NOMINAL'){
		                                        if(existNormalVote) {
			                                        var yesVotesNormal = $linq(vm.sessionComponent.session.executedOrder[idxExecutedOrder].votes).count("x => x.vote == 'YES' && x.type == 'ORDINARY'");
			                                        var noVotesNormal = $linq(vm.sessionComponent.session.executedOrder[idxExecutedOrder].votes).count("x => x.vote == 'NO' && x.type == 'ORDINARY'");
			                                        var abstentionVotesNormal = $linq(vm.sessionComponent.session.executedOrder[idxExecutedOrder].votes).count("x => x.vote == 'ABSTENTION' && x.type == 'ORDINARY'");
			                                        var noPresentVotesNormal = $linq(vm.sessionComponent.session.executedOrder[idxExecutedOrder].votes).count("x => x.vote == 'MISSING' && x.type == 'ORDINARY'");
			                                        vm.sessionComponent.session.executedOrder[idxExecutedOrder].voteState = getVoteState(numVotesTotalAux, yesVotesNormal, noVotesNormal, abstentionVotesNormal, noPresentVotesNormal);
			                                    }
		                                    }else{
		                                		if(existNormalVote) {
		                                			vm.sessionComponent.session.executedOrder[idxExecutedOrder].yesVotes = self.data.yesVotes;
				                                	vm.sessionComponent.session.executedOrder[idxExecutedOrder].noVotes = self.data.noVotes;
				                                	vm.sessionComponent.session.executedOrder[idxExecutedOrder].abstentionVotes = self.data.abstentionVotes;
				                                	vm.sessionComponent.session.executedOrder[idxExecutedOrder].missingVotes = self.data.missingVotes;
				                                	vm.sessionComponent.session.executedOrder[idxExecutedOrder].voteState = getVoteState(numVotesTotalAux, ((self.data.yesVotes)?self.data.yesVotes:0), ((self.data.noVotes)?self.data.noVotes:0), ((self.data.abstentionVotes)?self.data.abstentionVotes:0), ((self.data.missingVotes)?self.data.missingVotes:0));
			                                    }
		                                    }
		                                	if(vm.sessionComponent.session.executedOrder[idxExecutedOrder].voteState == 'REFUSED'){
	                                			_.forEach(vm.sessionComponent.session.executedOrder[idxExecutedOrder].votes, function (item, idx) {
	                                                vm.sessionComponent.session.executedOrder[idxExecutedOrder].votes[idx].vote = 'NO_VOTE';
	                                            })
	                                            vm.sessionComponent.session.executedOrder[idxExecutedOrder].yesVotes = 0;
			                                	vm.sessionComponent.session.executedOrder[idxExecutedOrder].noVotes = 0;
			                                	vm.sessionComponent.session.executedOrder[idxExecutedOrder].abstentionVotes = 0;
			                                	vm.sessionComponent.session.executedOrder[idxExecutedOrder].missingVotes = 0;
			                                	vm.sessionComponent.session.executedOrder[idxExecutedOrder].changeStateMotive = undefined;
		                                        vm.sessionComponent.session.executedOrder[idxExecutedOrder].voteStateUpdated = undefined;
		                                        
				                            }
		                                    RestService.update('./api/sec/session_executed_order/' + vm.sessionComponent.session.executedOrder[idxExecutedOrder].id, vm.sessionComponent.session.executedOrder[idxExecutedOrder])
		                                        .then(function (data) {
		                                        	modal.canModifyResult = true;
		                                        	modal.data.voteState = vm.sessionComponent.session.executedOrder[idxExecutedOrder].voteState;
		                                        	modal.voteState = $linq(modal.requiredVoteStatesModify).firstOrDefault(undefined,"x => x.id == '"+modal.data.voteState+"'");
		                                        	modal.extra.activeTab = 1;
		                                        	if(vm.sessionComponent.session.executedOrder[idxExecutedOrder].voteState == 'REFUSED'){
		                                        		DialogsFactory.notify('global.sec.literals.retirePointAllMissingOrAbstentionBody', $filter('translate')('global.sec.literals.retirePoint'));
		                                        		$rootScope.$broadcast('sessionPointOrderUpdated', {origin: 'executedOrder', modal: modal, point: point});
		                                        	}else{
		                                        		$rootScope.$broadcast('sessionPointOrderUpdated', {origin: 'executedOrder'});
		                                        	}
		                                        }).catch(function (error) {
		                                         var a = 0;
		                                    });
	                                	}
	                                }
	                            }
	                    	}
                            if(modal.canEdit && vm.canDeleteThisPoint(modal.data, true, false)){
	                            modal.submitLabel = 'global.sec.literals.retirePoint';
	                            modal.submitClass = 'grey';
	                            modal.submitFunction = function () {
	                                var self = this;

	                                DialogsFactory.confirm('global.sec.literals.retirePoint', 'global.sec.literals.retirePointBody', 'zMax3', 'zMax')
	                                    .then(function (data) {
	                                        var idxExecutedOrder = $linq(vm.sessionComponent.session.executedOrder).indexOf("x => x.id == " + self.data.id);

	                                        if(idxExecutedOrder != -1) {
	                                            vm.sessionComponent.session.executedOrder[idxExecutedOrder].voteState = 'REFUSED';

	                                            _.forEach(vm.sessionComponent.session.executedOrder[idxExecutedOrder].votes, function (item, idx) {
	                                                vm.sessionComponent.session.executedOrder[idxExecutedOrder].votes[idx].vote = 'NO_VOTE';
	                                            });
	                                            vm.sessionComponent.session.executedOrder[idxExecutedOrder].yesVotes = 0;
			                                	vm.sessionComponent.session.executedOrder[idxExecutedOrder].noVotes = 0;
			                                	vm.sessionComponent.session.executedOrder[idxExecutedOrder].abstentionVotes = 0;
			                                	vm.sessionComponent.session.executedOrder[idxExecutedOrder].missingVotes = 0;
			                                	vm.sessionComponent.session.executedOrder[idxExecutedOrder].changeStateMotive = undefined;
		                                        vm.sessionComponent.session.executedOrder[idxExecutedOrder].voteStateUpdated = undefined;
		                                        
	                                            RestService.update('./api/sec/session_executed_order/' + vm.sessionComponent.session.executedOrder[idxExecutedOrder].id, vm.sessionComponent.session.executedOrder[idxExecutedOrder])
	                                                .then(function (data) {
	                                                	$rootScope.$broadcast('sessionPointOrderUpdated', {origin: 'executedOrder', modal: modal, point: point});
	                                                }).catch(function (error) {
	                                                //Empty
	                                            });
	                                        }
	                                    }).catch(function (data) {
	                                    //Empty
	                                });

	                            }
                            }else if(modal.canEdit && modal.data.id && ((modal.data.voteStateUpdated && modal.data.voteStateUpdated == 'REFUSED') || (modal.data.voteState && modal.data.voteState == 'REFUSED') || (modal.data.voteStateUpdated && modal.data.voteStateUpdated == 'POSTPONED') || (modal.data.voteState && modal.data.voteState == 'POSTPONED'))){
                            	modal.submitLabel = 'global.sec.literals.keepPoint';
	                            modal.submitClass = 'grey';
	                            modal.submitFunction = function () {
	                                var self = this;
	                                DialogsFactory.confirm('global.sec.literals.keepPoint', 'global.sec.literals.keepPointBody', 'zMax3', 'zMax')
	                                    .then(function (data) {
	                                        var idxExecutedOrder = $linq(vm.sessionComponent.session.executedOrder).indexOf("x => x.id == " + self.data.id);

	                                        if(idxExecutedOrder != -1) {
	                                            vm.sessionComponent.session.executedOrder[idxExecutedOrder].voteState = 'NO_VOTE';
	                                            RestService.update('./api/sec/session_executed_order/' + vm.sessionComponent.session.executedOrder[idxExecutedOrder].id, vm.sessionComponent.session.executedOrder[idxExecutedOrder])
	                                                .then(function (data) {
	                                                	$rootScope.$broadcast('sessionPointOrderUpdated', {origin: 'executedOrder', modal: modal, point: point});
	                                                }).catch(function (error) {
	                                                //Empty
	                                            });
	                                        }
	                                    }).catch(function (data) {
	                                    //Empty
	                                });
	                            }
                            }
                            	
                            if(modal.canEdit && vm.canDeleteThisPoint(modal.data, false, false)){
	                            modal.extraSubmitLabel3 = 'global.literals.delete';
	                            modal.extraSubmitClass3 = 'grey';
	                            modal.extraSubmitFunction3 = function () {
	                                var self = this;

	                                DialogsFactory.confirm('global.sec.literals.deletePoint', 'global.sec.literals.deletePointBody', 'zMax3', 'zMax').then(function (data) {
	        	            			$http({
	        	    	                    url: './api/sec/session_executed_order/check_delete/' + modal.data.id,
	        	    	                    method: 'GET'
	        	    	                }).then(function(data) {
	        	    	                	if(data && data.data){
	        	    		                	$http({
	        	    		                        url: './api/sec/session_executed_order/' + modal.data.id,
	        	    		                        method: 'DELETE'
	        	    		                    }).then(function(data) {
	        	    		                    	var indexOfPoint = $linq(vm.sessionComponent.session.executedOrder).indexOf("x => x.id == "+modal.data.id);
	        	    		                    	if(indexOfPoint != -1) {
	        	    		                    		vm.sessionComponent.session.executedOrder.splice(indexOfPoint,1);
	        	                                    }
	        	    		                    	
	        	    		                    	var indexOfCollapsed = $linq(vm.initExecutedOrderCollapsed).indexOf("x => x.id == "+point.$nodeScope.$modelValue.executedOrder.id);
	        	    		                    	if(indexOfCollapsed != -1) {
	        	    		                    		vm.initExecutedOrderCollapsed.splice(indexOfCollapsed,1);
	        	                                    }
	        	    		                    	
	        	    		                    	$rootScope.$broadcast('sessionPointOrderUpdated', {origin: 'executedOrder'})
	        	    		                        $rootScope.$broadcast('sessionPointOrderDeleted', { origin: 'executedOrder' })
	        	    		                        modal.close();
	        	    		                    }).catch(function(error) {
	        	    		                        //Empty
	        	    		                    });
	        	    	                	}else{
	        	    	                		DialogsFactory.error($filter('translate')('global.sec.literals.canNotDelete'));
	        	    	                	}
	        	    	                }).catch(function(error) {
	        	    	                	 //Empty
	        	    	                });
	                    			}).catch(function (data) {
	            	                    //Empty
	            	                });
	                            }
                            }
	                    	modal.changeVoteState = function(){
	                    		var self = this;
	                    		var saveUpdateStateVote = function(modalModify){
	                    			
	                    			var idxExecutedOrder = $linq(vm.sessionComponent.session.executedOrder).indexOf("x => x.id == " + self.data.id);
	                    			
                                    if(idxExecutedOrder != -1) {
                                    	vm.sessionComponent.session.executedOrder[idxExecutedOrder].changeStateMotive = modalModify.annexaFormly.model.row1.changeStateMotive;
                                        vm.sessionComponent.session.executedOrder[idxExecutedOrder].voteStateUpdated = modalModify.annexaFormly.model.row1.voteStateUpdated.id;
                                        
		                    			RestService.update('./api/sec/session_executed_order/' + vm.sessionComponent.session.executedOrder[idxExecutedOrder].id, vm.sessionComponent.session.executedOrder[idxExecutedOrder])
	                                    .then(function (data) {
	                                    	self.data.voteStateUpdated = vm.sessionComponent.session.executedOrder[idxExecutedOrder].voteState;
	                                    	self.data.changeStateMotive = vm.sessionComponent.session.executedOrder[idxExecutedOrder].changeStateMotive;
	                                    	self.voteStateUpdated = $linq(self.requiredVoteStatesModify).firstOrDefault(undefined,"x => x.id == '"+data.voteStateUpdated+"'");
	                                    	modalModify.close();
	                                        $rootScope.$broadcast('sessionPointOrderUpdated', {origin: 'executedOrder', modal: modal, point: point});
	                                    }).catch(function (error) {
	                                     var a = 0;
	                                    });
                                    }
	                    		}
	                    		var modalModify = angular.copy(SecModals.sessionChangeVoteState);
	                    		modalModify.annexaFormly.model = {};
	                    		modalModify.annexaFormly.model.row1 = {voteStateUpdatedAux:((self.data.voteStateUpdated == 'APPROVED')?'NOT_APPROVED':'APPROVED')};
	                    		modalModify.annexaFormly.options = {};
	        	                AnnexaFormlyFactory.showModal('modalNewSessionPoint', modalModify, saveUpdateStateVote, false);
	                    	}
	                    	modal.deleteVoteStateUpdate = function(){
	                    		var self = this;
	                    		DialogsFactory.confirm('global.sec.literals.deleteChangeState', 'global.sec.literals.deleteChangeStateBody', 'zMax3', 'zMax').then(function (data) {
	                    			var idxExecutedOrder = $linq(vm.sessionComponent.session.executedOrder).indexOf("x => x.id == " + self.data.id);
	                    			if(idxExecutedOrder != -1) {
                                        vm.sessionComponent.session.executedOrder[idxExecutedOrder].changeStateMotive = undefined;
                                        vm.sessionComponent.session.executedOrder[idxExecutedOrder].voteStateUpdated = undefined;
                                        
		                    			RestService.update('./api/sec/session_executed_order/' + vm.sessionComponent.session.executedOrder[idxExecutedOrder].id, vm.sessionComponent.session.executedOrder[idxExecutedOrder])
	                                    .then(function (data) {
	                                    	modal.voteStateUpdated = undefined;
	                                    	modal.data.changeStateMotive = undefined;
	                                    	modal.data.voteStateUpdated = undefined;
	                                    	$rootScope.$broadcast('sessionPointOrderUpdated', {origin: 'executedOrder'});
	                                    }).catch(function (error) {
		                                     var a = 0;
		                                });
	                    			}
	                    		}).catch(function (data) {
				                    //Empty
			                    });
	                    	}
		                    AnnexaModalFactory.showModal('modalPointEdit', modal);
		                    if(modalAux){
		                    	modalAux.close();
		                    }
		                }).catch(function (error) {
		                	if(modalAux){
		                    	modalAux.close();
		                    }
		            });
            	}
            }
            
            vm.newPoint = function(isGroup) {
            	vm.addPointLevel(undefined, isGroup, undefined);
            }            
            
            vm.addPointLevel = function(point, group, sameLevel ){
            	var openModal = false;
            	var groupPoints = undefined;
            	if(group && SecFactory.session && SecFactory.session.organ && SecFactory.session.organ.agendaPointTypes){
            		groupPoints = $linq(SecFactory.session.organ.agendaPointTypes).where("x => x.type == 'GROUP'").toArray();
            		if(groupPoints && groupPoints.length > 0){
            			openModal = true;
            		}
            	}else{
            		openModal = true;
            	}
            	if(openModal){
            		var modal = angular.copy(SecModals.sessionPointNew);
	                modal.annexaFormly.model = {};
	                modal.annexaFormly.model.row1 = {};
                	modal.annexaFormly.options = {};
	                modal.annexaFormly.fields[0].fieldGroup[0].templateOptions.isGroup = group;
	                if(group){
	                	modal.title = "global.sec.literals.newGroup";
	                }
	                modal.extra = { 
	                		session: vm.sessionComponent.session, 
	                		apiBaseUrl: './api/sec/session_executed_order', 
	                		sessionProperty: 'executedOrder', 
	                		point: ((point && point.$nodeScope && point.$nodeScope.$modelValue && point.$nodeScope.$modelValue.executedOrder)?point.$nodeScope.$modelValue.executedOrder : undefined), 
	                		sameLevel: sameLevel
	                };
	                AnnexaFormlyFactory.showModal('modalNewSessionPoint', modal, vm.savePoint, false);
            	}else{
            		DialogsFactory.error($filter('translate')('global.sec.literals.organHaveNoGroupPoints'));
            	}
            }

            vm.deletePoint = function(point, retire, postponed){
            	if(point && point.$nodeScope && point.$nodeScope.$modelValue && point.$nodeScope.$modelValue.executedOrder && point.$nodeScope.$modelValue.executedOrder.id ){
            		if(retire){
            			var modal = angular.copy(SecModals.retireSessionPoint);
			            modal.alerts = [];
			            modal.annexaFormly.model = {};
			            modal.annexaFormly.model.modal_body = {};
			            modal.annexaFormly.options = {};
			            modal.annexaFormly.options.formState = {readOnly: false};
			            var retireSessionPoint = function () {
			                if (this.annexaFormly.model.modal_body.reason) {
			                	var observations = this.annexaFormly.model.modal_body.reason;
			                	DialogsFactory.confirm('global.sec.literals.retirePoint', 'global.sec.literals.retirePointBody', 'zMax3', 'zMax').then(function (data) {
			                        var idxExecutedOrder = $linq(vm.sessionComponent.session.executedOrder).indexOf("x => x.id == " + point.$nodeScope.$modelValue.executedOrder.id);
			
			                        if(idxExecutedOrder != -1) {
			                            vm.sessionComponent.session.executedOrder[idxExecutedOrder].voteState = 'REFUSED';
			                            vm.sessionComponent.session.executedOrder[idxExecutedOrder].observations = observations;
			                            _.forEach(vm.sessionComponent.session.executedOrder[idxExecutedOrder].votes, function (item, idx) {
			                                vm.sessionComponent.session.executedOrder[idxExecutedOrder].votes[idx].vote = 'NO_VOTE';
			                            })
			                            vm.sessionComponent.session.executedOrder[idxExecutedOrder].yesVotes = 0;
			                            vm.sessionComponent.session.executedOrder[idxExecutedOrder].noVotes = 0;
			                            vm.sessionComponent.session.executedOrder[idxExecutedOrder].abstentionVotes = 0;
			                            vm.sessionComponent.session.executedOrder[idxExecutedOrder].missingVotes = 0;
			                            vm.sessionComponent.session.executedOrder[idxExecutedOrder].changeStateMotive = undefined;
                                        vm.sessionComponent.session.executedOrder[idxExecutedOrder].voteStateUpdated = undefined;
                                        
			                            RestService.update('./api/sec/session_executed_order/' + vm.sessionComponent.session.executedOrder[idxExecutedOrder].id, vm.sessionComponent.session.executedOrder[idxExecutedOrder])
			                                .then(function (data) {
			                                    $rootScope.$broadcast('sessionPointOrderDeleted', {origin: 'executedOrder'})
			                                }).catch(function (error) {
			                                //Empty
			                            });
			                        }
			                    }).catch(function (data) {
			                    //Empty
			                    });
			                    modal.close();
			                }
			            };
			
			            AnnexaFormlyFactory.showModal("modalRetireSessionPoint", modal, retireSessionPoint, false);
            		}else if(postponed){
            			var modal = angular.copy(SecModals.retireSessionPoint);
            			modal.title = 'global.sec.literals.postponedPoint';
			            modal.alerts = [];
			            modal.annexaFormly.model = {};
			            modal.annexaFormly.model.modal_body = {};
			            modal.annexaFormly.options = {};
			            modal.annexaFormly.options.formState = {readOnly: false};
			            var postponedSessionPoint = function () {
			                if (this.annexaFormly.model.modal_body.reason) {
			                	var observations = this.annexaFormly.model.modal_body.reason;
			                	DialogsFactory.confirm('global.sec.literals.postponedPoint', 'global.sec.literals.postponedPointBody', 'zMax3', 'zMax').then(function (data) {
			                        var idxExecutedOrder = $linq(vm.sessionComponent.session.executedOrder).indexOf("x => x.id == " + point.$nodeScope.$modelValue.executedOrder.id);
			                        if(idxExecutedOrder != -1) {
			                            vm.sessionComponent.session.executedOrder[idxExecutedOrder].voteState = 'POSTPONED';
			                            vm.sessionComponent.session.executedOrder[idxExecutedOrder].observations = observations;
			                            _.forEach(vm.sessionComponent.session.executedOrder[idxExecutedOrder].votes, function (item, idx) {
			                                vm.sessionComponent.session.executedOrder[idxExecutedOrder].votes[idx].vote = 'NO_VOTE';
			                            })
			                            vm.sessionComponent.session.executedOrder[idxExecutedOrder].yesVotes = 0;
			                            vm.sessionComponent.session.executedOrder[idxExecutedOrder].noVotes = 0;
			                            vm.sessionComponent.session.executedOrder[idxExecutedOrder].abstentionVotes = 0;
			                            vm.sessionComponent.session.executedOrder[idxExecutedOrder].missingVotes = 0;
			                            vm.sessionComponent.session.executedOrder[idxExecutedOrder].changeStateMotive = undefined;
                                        vm.sessionComponent.session.executedOrder[idxExecutedOrder].voteStateUpdated = undefined;
                                        
			                            RestService.update('./api/sec/session_executed_order/' + vm.sessionComponent.session.executedOrder[idxExecutedOrder].id, vm.sessionComponent.session.executedOrder[idxExecutedOrder])
			                                .then(function (data) {
			                                    $rootScope.$broadcast('sessionPointOrderDeleted', {origin: 'executedOrder'})
			                                }).catch(function (error) {
			                                //Empty
			                            });
			                        }
			                    }).catch(function (data) {
			                    //Empty
			                    });
			                    modal.close();
			                }
			            };
			
			            AnnexaFormlyFactory.showModal("modalRetireSessionPoint", modal, postponedSessionPoint, false);
            		}else{
            			DialogsFactory.confirm('global.sec.literals.deletePoint', 'global.sec.literals.deletePointBody', 'zMax3', 'zMax').then(function (data) {
	            			$http({
	    	                    url: './api/sec/session_executed_order/check_delete/' + point.$nodeScope.$modelValue.executedOrder.id,
	    	                    method: 'GET'
	    	                }).then(function(data) {
	    	                	if(data && data.data){
	    		                	$http({
	    		                        url: './api/sec/session_executed_order/' + point.$nodeScope.$modelValue.executedOrder.id,
	    		                        method: 'DELETE'
	    		                    }).then(function(data) {
	    		                    	var indexOfPoint = $linq(vm.sessionComponent.session.executedOrder).indexOf("x => x.id == "+point.$nodeScope.$modelValue.executedOrder.id);
	    		                    	if(indexOfPoint != -1) {
	    		                    		vm.sessionComponent.session.executedOrder.splice(indexOfPoint,1);
	                                    }
	    		                    	
	    		                    	var indexOfCollapsed = $linq(vm.initExecutedOrderCollapsed).indexOf("x => x.id == "+point.$nodeScope.$modelValue.executedOrder.id);
	    		                    	if(indexOfCollapsed != -1) {
	    		                    		vm.initExecutedOrderCollapsed.splice(indexOfCollapsed,1);
	                                    }
	    		                    	
	    		                        $rootScope.$broadcast('sessionPointOrderDeleted', { origin: 'executedOrder' })
	    		                    }).catch(function(error) {
	    		                        //Empty
	    		                    });
	    	                	}else{
	    	                		DialogsFactory.error($filter('translate')('global.sec.literals.canNotDelete'));
	    	                	}
	    	                }).catch(function(error) {
	    	                	 //Empty
	    	                });
            			}).catch(function (data) {
    	                    //Empty
    	                });
            		}
            	}
            }
            
            vm.keepPoint = function(point){
            	if(point && point.$nodeScope && point.$nodeScope.$modelValue && point.$nodeScope.$modelValue.executedOrder && point.$nodeScope.$modelValue.executedOrder.id 
            			&& ((point.$nodeScope.$modelValue.executedOrder.voteStateUpdated && point.$nodeScope.$modelValue.executedOrder.voteStateUpdated == 'REFUSED') || (point.$nodeScope.$modelValue.executedOrder.voteState && point.$nodeScope.$modelValue.executedOrder.voteState == 'REFUSED') ||
            					(point.$nodeScope.$modelValue.executedOrder.voteStateUpdated && point.$nodeScope.$modelValue.executedOrder.voteStateUpdated == 'POSTPONED') || (point.$nodeScope.$modelValue.executedOrder.voteState && point.$nodeScope.$modelValue.executedOrder.voteState == 'POSTPONED'))){
            		DialogsFactory.confirm('global.sec.literals.keepPoint', 'global.sec.literals.keepPointBody', 'zMax3', 'zMax').then(function (data) {
                        var idxExecutedOrder = $linq(vm.sessionComponent.session.executedOrder).indexOf("x => x.id == " + point.$nodeScope.$modelValue.executedOrder.id);
                        if(idxExecutedOrder != -1) {
                            vm.sessionComponent.session.executedOrder[idxExecutedOrder].voteState = 'NO_VOTE';
                            
                            RestService.update('./api/sec/session_executed_order/' + vm.sessionComponent.session.executedOrder[idxExecutedOrder].id, vm.sessionComponent.session.executedOrder[idxExecutedOrder])
                                .then(function (data) {
                                    $rootScope.$broadcast('sessionPointOrderDeleted', {origin: 'executedOrder'})
                                }).catch(function (error) {
                                //Empty
                            });
                        }
                    }).catch(function (data) {
                    //Empty
                    });
            	}
            }
            
            vm.canDeleteThisPoint = function(point, retire, posponed){
            	var proposedOrderIdJSON = undefined;
            	var executedOrderId = undefined;
            	var executeOrderRetired = undefined;
            	var executeOrderPostponed = undefined;
            	if(point.$nodeScope){
            		proposedOrderIdJSON = point.$nodeScope.$modelValue.executedOrder.proposedOrderIdJSON;
            		executedOrderId = point.$nodeScope.$modelValue.executedOrder.id;
            		executeOrderRetired = (((point.$nodeScope.$modelValue.executedOrder.voteStateUpdated && point.$nodeScope.$modelValue.executedOrder.voteStateUpdated == 'REFUSED') || (point.$nodeScope.$modelValue.executedOrder.voteState && point.$nodeScope.$modelValue.executedOrder.voteState == 'REFUSED'))? true:false);
            		executeOrderPostponed = (((point.$nodeScope.$modelValue.executedOrder.voteStateUpdated && point.$nodeScope.$modelValue.executedOrder.voteStateUpdated == 'POSTPONED') || (point.$nodeScope.$modelValue.executedOrder.voteState && point.$nodeScope.$modelValue.executedOrder.voteState == 'POSTPONED'))? true:false);
            	}else{
            		proposedOrderIdJSON = point.proposedOrderIdJSON;
            		executedOrderId = point.id;
            		executeOrderRetired = (((point.voteStateUpdated && point.voteStateUpdated == 'REFUSED') || (point.voteState && point.voteState == 'REFUSED'))? true:false);
            		executeOrderPostponed = (((point.voteStateUpdated && point.voteStateUpdated == 'POSTPONED') || (point.voteState && point.voteState == 'POSTPONED'))? true:false);
            	}
            	if($linq(vm.sessionComponent.session.executedOrder).count("x => x.parent && x.parent.id == "+executedOrderId) > 0){
            		return false;
            	}else{
            		if(retire){
            			if(executeOrderRetired){
            				return false;
            			}else{
            				return true;
            			}
            		}else if(posponed){
            			if(executeOrderPostponed){
            				return false;
            			}else{
            				return true;
            			}
            		}else{
            			if(proposedOrderIdJSON){
                			return false;
            			}else{
            				return true;
            			}
            		}
            	}
            }
            
            var savePointTree = function(point, deferred){
    			RestService.update('./api/sec/session_executed_order/move/' + point.id, point).then(function (data) {
    				if(data && data.session && data.session.executedOrder){
    					vm.sessionComponent.session.executedOrder = data.session.executedOrder;    					
    				}
                    deferred.resolve(true)
                }).catch(function (error) {
                	deferred.resolve(false)
                });
            }
            
            vm.canStartSession = function (){
            	var attendeesToNotify = $linq(vm.sessionComponent.session.attendees).count(
                    	function(x) {
                    		var doNotification = false;
                    		if(!x.conveneNotification && x.memberRol.id != $rootScope.app.configuration.sec_organ_member_rols.extern && x.memberRol.id != $rootScope.app.configuration.sec_organ_member_rols.notAssign) {
                    			if(x.user && x.user.id){
                    				var organMember = $linq(vm.sessionComponent.session.organ.members).firstOrDefault(undefined, "x => x.user && x.user.id == "+x.user.id);
                    				if(organMember && organMember.attendeeAddressConvene){
                    					doNotification = true;
                    				}
                    			}
                            }
                    		return doNotification;
                    	}
                    );
            	return attendeesToNotify == 0;
            }
            
            vm.startSession = function () {
                var session = vm.sessionComponent.session;

                var notConfirmedAssistants = $linq(session.attendees).count("x => x.attendeeType == null");

                if(notConfirmedAssistants == 0) {
                    DialogsFactory.confirm('global.sec.literals.startSession', 'global.sec.literals.startSessionBody')
                        .then(function (data) {
                            var modal = angular.copy(SecModals.startSession);
                            modal.annexaFormly.model = {};
                            modal.annexaFormly.model.modal_body = { startSessionDate: new Date(), startSessionTime: new Date() };
                            modal.sessionId = session.id;
                            AnnexaFormlyFactory.showModal("startSessionModal", modal, vm.sessionStart, false);
                        }).catch(function (data) {
                        //Empty
                    });
                } else {
                    DialogsFactory.error('global.sec.errors.noConfirmedAssitance', 'Error');
                }
            }
            
	        vm.sessionStart = function (session) {
	        	var startCompleteDateSession = session.annexaFormly.model.modal_body.startSessionDate && session.annexaFormly.model.modal_body.startSessionTime ?
	                    new Date(Date.UTC(session.annexaFormly.model.modal_body.startSessionDate.getFullYear(),
	                    				  session.annexaFormly.model.modal_body.startSessionDate.getMonth(),
	                    				  session.annexaFormly.model.modal_body.startSessionDate.getDate(),
	                    				  session.annexaFormly.model.modal_body.startSessionTime.getUTCHours(),
	                    				  session.annexaFormly.model.modal_body.startSessionTime.getUTCMinutes())) : null;
	           if(vm.sessionComponent.session.sessionDateFirstConvene && startCompleteDateSession && vm.sessionComponent.session.sessionDateFirstConvene > startCompleteDateSession){
	        	   session.close();
	        	   DialogsFactory.confirm('global.sec.literals.startSession', 'global.sec.literals.startSessionAlertBody')
	                 .then(function (data) {
						SecFactory.startSessionWithDate(session.sessionId, startCompleteDateSession).then(function (data) {
							updateStartCancelSession(data);
							$state.reload();
						}).catch(function (error) {
							if(error && error.data && error.data.message && error.data.message.toUpperCase().startsWith("We are working with this session.".toUpperCase())){
								DialogsFactory.error($filter('translate')('global.literals.errorSessionWorking'), $filter('translate')('DIALOGS_ERROR_MSG'));
							}
						});
	                 }).catch(function (data) {


	                 });
	           }else{
	        	   SecFactory.startSessionWithDate(session.sessionId, startCompleteDateSession).then(function (data) {
						updateStartCancelSession(data);
						session.close();
						$state.reload();
					}).catch(function (error) {
						if(error && error.data && error.data.message && error.data.message.toUpperCase().startsWith("We are working with this session.".toUpperCase())){
							DialogsFactory.error($filter('translate')('global.literals.errorSessionWorking'), $filter('translate')('DIALOGS_ERROR_MSG'));
						}
					});
	           }
	        	 
	        };
        
           	vm.editAgreementDocument = function(point){
            	if(point && point.$nodeScope && point.$nodeScope.$modelValue && point.$nodeScope.$modelValue.executedOrder && point.$nodeScope.$modelValue.executedOrder.agreementDocument){
            		DccumentsFactory.getEditOnlineUrl(point.$nodeScope.$modelValue.executedOrder.agreementDocument).then(function(data) {
                        window.location.href = data;
                    }).catch(function(error) {
                    	DialogsFactory.error('global.sec.literals.noAgreementDocument', 'Error');
                    });
            	}else{
            		DialogsFactory.error('global.sec.literals.noAgreementDocument', 'Error');
            	}
            }
            
           	vm.downloadAgreementDocument = function(point){
        	    $rootScope.loading(true);

                $http.get("./api/repodocs/downloadAlfDoc?id="+point.$nodeScope.$modelValue.executedOrder.agreementDocument, {responseType: 'arraybuffer'})
                    .success(function (data, status, headers) {
                        var retDownload = {
                            file: undefined,
                            fileName: undefined,
                            linkElement: undefined,
                            clickEvent: undefined
                        }
                        headers = headers();

                        var contentType = headers['content-type'];
                        retDownload.file = new Blob([data], {type: contentType});
                        if(point.$nodeScope.$modelValue.executedOrder.title){
                        	retDownload.fileName = point.$nodeScope.$modelValue.executedOrder.id+"_"+point.$nodeScope.$modelValue.executedOrder.title.replace(/\s/g,"");
                        }else{
                        	retDownload.fileName = point.$nodeScope.$modelValue.executedOrder.id;
                        }
                        retDownload.fileName += '.docx';
                        

                        try {
                            if (window.navigator.msSaveOrOpenBlob) {
                                window.navigator.msSaveOrOpenBlob(retDownload.file, retDownload.fileName);
                            } else {
                                retDownload.linkElement = document.createElement('a');
                                var url = window.URL.createObjectURL(retDownload.file);
                                retDownload.linkElement.setAttribute('href', url);
                                retDownload.linkElement.setAttribute("download", retDownload.fileName);
                                retDownload.clickEvent = new MouseEvent("click", {
                                    "view": window,
                                    "bubbles": true,
                                    "cancelable": false
                                });
                                retDownload.linkElement.dispatchEvent(retDownload.clickEvent);
                            }
                            $rootScope.loading(false);
                        } catch (e) {
                            $rootScope.loading(false);
                        }
                    }).error(function (error) {
                        $rootScope.loading(false);
                });
           	}
            vm.cancelSession = function() {
                var session = vm.sessionComponent.session;

                var cancelMessage = 'global.sec.literals.cancelSessionBody';

                if( $linq(session.executedOrder).count("x => x.voteState != 'NO_VOTE'") > 0) {
                    cancelMessage = 'global.sec.literals.cancelSessionWithVotesBody';
                }

                DialogsFactory.confirm('global.sec.literals.cancelSession', cancelMessage)
                    .then(function (data) {
                        SecFactory.cancelStartSession(session.id)
                            .then(function(data) {
                                updateStartCancelSession(data);
                            }).catch(function (error) {
	                            if(error && error.data && error.data.message && error.data.message.toUpperCase().startsWith("We are working with this session.".toUpperCase())){
									DialogsFactory.error($filter('translate')('global.literals.errorSessionWorking'), $filter('translate')('DIALOGS_ERROR_MSG'));
	                            }
                        });
                    }).catch(function (data) {
                        //Empty
                });
            }
            
            vm.endSession = function (){
            	if(vm.sessionComponent.canEndSession()){	
            		if(!vm.sessionComponent.session.realFinishSessionDate){
	            		DialogsFactory.confirm('global.sec.literals.endSession', 'global.sec.literals.endSessionBody').then(function (data) {	
	                        var modal = angular.copy(SecModals.finishSession);	
	                        modal.annexaFormly.model = {};	
	                        modal.annexaFormly.model.modal_body = { finishSessionDate: new Date(), finishSessionTime: new Date() };	
	                        modal.sessionId = vm.sessionComponent.session.id;	
	                        AnnexaFormlyFactory.showModal("finishSessionModal", modal, vm.finishSession, false);	
	                    }).catch(function (data) {	
	                    	//Empty	
	                    });
            		}else{
            			SecFactory.endSession(vm.sessionComponent.session.id).then(function(data) {
                        	$state.reload();
                        }).catch(function (error) {
	                        if(error && error.data && error.data.message && error.data.message.toUpperCase().startsWith("We are working with this session.".toUpperCase())){
								DialogsFactory.error($filter('translate')('global.literals.errorSessionWorking'), $filter('translate')('DIALOGS_ERROR_MSG'));
	                        }
						//Empty
                    });
            		}
            	}else{
            		if(!_.contains(['STARTED'],vm.sessionComponent.session.state)){
            			DialogsFactory.error($filter('translate')('global.sec.errors.cantFinishSessionStateIncorrect'));
            		}else{
            			var votePoints = $linq(vm.sessionComponent.session.executedOrder).where(function(x) {
            				if(!_.contains(['GROUP', 'NOVOTE'], x.type.type)) {
                                if(_.contains(['VOTE'], x.type.type)){
                                	return true;
                                }else if(_.contains(['PROPOSAL','OPINION'], x.type.type) && x.type.vote){
                                	return true;
                                }
                            }
                            return false;
                        }).toArray();
            			if($linq(votePoints).count("x => x.voteState == 'NO_VOTE'") != 0){
            				DialogsFactory.error($filter('translate')('global.sec.errors.cantFinishSessionPointsNoVoted'));
            			}else{
            				DialogsFactory.error($filter('translate')('global.sec.errors.cantFinishSession'));
            			}
            		}

            	}
            }

	        vm.finishSession = function (session) {
	        	var finishCompleteDateSession = session.annexaFormly.model.modal_body.finishSessionDate && session.annexaFormly.model.modal_body.finishSessionTime ?
	                    new Date(Date.UTC(session.annexaFormly.model.modal_body.finishSessionDate.getFullYear(),
	                    				  session.annexaFormly.model.modal_body.finishSessionDate.getMonth(),
	                    				  session.annexaFormly.model.modal_body.finishSessionDate.getDate(),
	                    				  session.annexaFormly.model.modal_body.finishSessionTime.getUTCHours(),
	                    				  session.annexaFormly.model.modal_body.finishSessionTime.getUTCMinutes())) : null;
	                    
	            if( vm.sessionComponent.session.realStartSessionDate && finishCompleteDateSession &&  vm.sessionComponent.session.realStartSessionDate > finishCompleteDateSession){
	            	session.close();
	            	DialogsFactory.error($filter('translate')('global.sec.literals.endSessionFinishDateErrorBody'));
	            }else if(finishCompleteDateSession > new Date() ){
	            	session.close();
	            	DialogsFactory.confirm('global.sec.literals.endSession', 'global.sec.literals.endSessionAlertBody')
	                 .then(function (data) {
					    SecFactory.endSessionWithDate(session.sessionId, finishCompleteDateSession).then(function (data) {
					    	$state.reload();
						}).catch(function (error) {
							if(error && error.data && error.data.message && error.data.message.toUpperCase().startsWith("We are working with this session.".toUpperCase())){
								DialogsFactory.error($filter('translate')('global.literals.errorSessionWorking'), $filter('translate')('DIALOGS_ERROR_MSG'));
							}
						});
		            }).catch(function (data) {
	
	                });
	            }else{
	            	SecFactory.endSessionWithDate(session.sessionId, finishCompleteDateSession).then(function (data) {
	            		session.close();
	            		$state.reload();
					}).catch(function (error) {
						if(error && error.data && error.data.message && error.data.message.toUpperCase().startsWith("We are working with this session.".toUpperCase())){
							DialogsFactory.error($filter('translate')('global.literals.errorSessionWorking'), $filter('translate')('DIALOGS_ERROR_MSG'));
						}
					});
	            }
	        };
	        
	        vm.globalVote = function() {
	        	var modal = angular.copy(SecModals.globalVoteModal);
	        	var numAttendeeTotalVote = $linq(vm.sessionComponent.session.attendees).count("x => x.vote");
	        	var numAttendeeAbssentVote = $linq(vm.sessionComponent.session.attendees).count("x => x.vote && (x.attendeeType == 'EXCUSE_ABSENCE' || x.attendeeType == 'NOT_ASSISTS')");
                var attendeesAbssentVote = $linq(vm.sessionComponent.session.attendees).where("x => x.vote && (x.attendeeType == 'EXCUSE_ABSENCE' || x.attendeeType == 'NOT_ASSISTS')").toArray();
	        	modal.canEdit = _.contains(['CONVENED', 'STARTED'], vm.sessionComponent.session.state);
	        	modal.extra = {maxVotes: numAttendeeTotalVote};
	        	
	        	
	        	
	        	modal.executedOrderSec = $linq(vm.sessionComponent.session.executedOrder).where("x => (x.votationType == 'ORDINARY' || x.votationType == 'SECRET') && x.type.vote && x.voteState != 'REFUSED'").toArray();
	        	if(modal.executedOrderSec.length > 1) {
	        		modal.data = {
		        			yesVotes: 0,
		        			noVotes: 0,
		        			abstentionVotes: 0,
		        			missingVotes: ((attendeesAbssentVote.length > 0)? numAttendeeAbssentVote: 0)};
	        	} else {
	        		modal.executedOrderSec = undefined;
	        	}
	        	
	        	modal.executedOrderNom = $linq(vm.sessionComponent.session.executedOrder).where("x => x.votationType == 'NOMINAL' && x.type.vote && x.voteState != 'REFUSED'").toArray();
	        	if(modal.executedOrderNom.length > 1) {
	        		modal.executedOrderVotes = {votes : []};
	        		_.forEach(modal.executedOrderNom[0].votes, function(item) {
	        			if(item.attendee && item.attendee.attendeeType && (item.attendee.attendeeType=="EXCUSE_ABSENCE" || item.attendee.attendeeType=="NOT_ASSISTS")){
	        				modal.executedOrderVotes.votes.push({
		        				id: item.id,
		        				attendee : item.attendee,
		        				type : item.type,
		        				vote : 'MISSING'
		        			});
	        			}else{
	        				modal.executedOrderVotes.votes.push({
		        				id: item.id,
		        				attendee : item.attendee,
		        				type : item.type,
		        				vote : 'NO_VOTE'
		        			});
	        			}
	        			
	                });
	        	} else {
	        		modal.executedOrderNom = undefined;
	        	}
	        	modal.calcResult = function(type){
                    var self = this;
                    var error = false;
                    var executedOrdersUpdate = [];
                    var calculateNeededVotesToAprove = function(numVotes, numAttendeeVoted, numAttendeeVotedNoAbstention, requiredVoteState){
                    	var neededVotes = 0;
                    	if(requiredVoteState == 'APPROVED_SIMPLE_MAJORITY'){
                    		if(numAttendeeVotedNoAbstention > 0){
                    			neededVotes = Math.ceil(numAttendeeVotedNoAbstention/2);
                    		}else{
                    			neededVotes = -1;
                    		}
                    	}else if(requiredVoteState == 'APPROVED_ABSOLUTE_MAJORITY'){
                    		if(numVotes > 0){
                    			neededVotes = Math.ceil(numVotes/2);
                    		}else{
                    			neededVotes = -1;
                    		}
                    	}else if(requiredVoteState == 'APPROVED_QUALIFIED_TWO_THIRDS'){
                    		if(numVotes > 0){
                    			var twoThids = Math.ceil((numAttendeeVoted*2)/3);
                    			var absolute = Math.ceil(numVotes/2);
                    			neededVotes = ((twoThids > absolute)?twoThids:absolute);
                    		}else{
                    			neededVotes = -1;
                    		}
                    	}else{
                    		//Si no esta informat agafem que sigui la simple.
                    		if(numAttendeeVotedNoAbstention > 0){
                    			neededVotes = Math.ceil(numAttendeeVotedNoAbstention/2);
                    		}else{
                    			neededVotes = -1;
                    		}
                    	}
                    	return neededVotes;
                    }
                    var getVoteState = function(numVotes, yesVotes, noVotes, abstentionVotes, noPresentVotes, requiredVoteState) {
                    	var numAttendeeVotedNoAbstentionts = numVotes - noPresentVotes - abstentionVotes;
                    	var numAttendeeVoted = numVotes - noPresentVotes;
                    	var neededVotesToAprove = calculateNeededVotesToAprove(numVotes,numAttendeeVoted,numAttendeeVotedNoAbstentionts, requiredVoteState);
                    	
                    	if(abstentionVotes > 0 && abstentionVotes == numAttendeeVoted){
                        	 return 'REFUSED';
                        } else if(noPresentVotes > 0 && noPresentVotes == numVotes){
                        	 return 'REFUSED';
                        } else if((abstentionVotes + noPresentVotes) > 0 && (abstentionVotes + noPresentVotes) == numVotes){
                        	 return 'REFUSED';
                        } else if (numVotes != numAttendeeTotalVote || neededVotesToAprove < 0) {
                            return 'NO_VOTE';
                        } else  {
                            if (noVotes > yesVotes) {
                                return 'NOT_APPROVED';
                            } else if (yesVotes < neededVotesToAprove){
                            	return 'NOT_APPROVED';
                            } else if (noVotes == 0 && abstentionVotes == 0 && yesVotes == numVotes ) {
                            	return 'APPROVED';
                            } else if (noVotes == 0 && abstentionVotes == 0 && yesVotes == numAttendeeVoted ) {
                            	return 'APPROVED';
                            } else if (yesVotes >= Math.ceil((numAttendeeVoted*2)/3) && yesVotes >= Math.ceil(numVotes/2)) {
                            	return 'APPROVED';
                            } else if (yesVotes >= Math.ceil((numVotes*3)/5)) {
                            	return 'APPROVED';
                            } else if (yesVotes >= Math.ceil(numVotes/2)) {
                            	return 'APPROVED';
                            } else if (yesVotes >= Math.ceil(numAttendeeVotedNoAbstentionts/2)) {
                        		return 'APPROVED'
                            } else {
                                return 'NOT_APPROVED';
                            }
                        }
                    }
                    var numVotesTotalAuxSec = 0;
                	var numVotesTotalAuxNominal = 0;
                    if(type == 'SECRET' && !error && self.executedOrderSec && self.executedOrderSec.length > 1) {
                    	numVotesTotalAuxSec = self.data.yesVotes + self.data.noVotes + self.data.abstentionVotes + self.data.missingVotes;
                    	if(numVotesTotalAuxSec != numAttendeeTotalVote){
                        	DialogsFactory.error($filter('translate')('global.sec.literals.numVotesIsNotcorrect'));
                        	error = true;
                    	}
                    }
                    if(type == 'NOMINAL' && !error && self.executedOrderNom && self.executedOrderNom.length > 1) {
                    	numVotesTotalAuxNominal = $linq(self.executedOrderVotes.votes).count("x =>x.vote != 'NO_VOTE' && x.type == 'ORDINARY'");
                    	if(numVotesTotalAuxNominal != numAttendeeTotalVote){
                        	DialogsFactory.error($filter('translate')('global.sec.literals.numVotesIsNotcorrect'));
                        	error = true;
                        }
                    }
                    
                    if(type == 'SECRET' && !error && self.executedOrderSec && self.executedOrderSec.length > 1) {
                        _.forEach(self.executedOrderSec, function (executedOrder) {
                        	var idxExecutedOrder = $linq(vm.sessionComponent.session.executedOrder).indexOf("x => x.id == " + executedOrder.id);
                        	var numVotesNormal = 0;
                        	executedOrder = angular.copy(vm.sessionComponent.session.executedOrder[idxExecutedOrder]);
                        	executedOrder.yesVotes = self.data.yesVotes;
                        	executedOrder.noVotes = self.data.noVotes;
                        	executedOrder.abstentionVotes = self.data.abstentionVotes;
                        	executedOrder.missingVotes = self.data.missingVotes;
                        	executedOrder.voteState = getVoteState(numVotesTotalAuxSec, ((self.data.yesVotes)?self.data.yesVotes:0), ((self.data.noVotes)?self.data.noVotes:0), ((self.data.abstentionVotes)?self.data.abstentionVotes:0), ((self.data.missingVotes)?self.data.missingVotes:0), executedOrder.requiredVoteState);
                        	if(executedOrder.voteState == 'REFUSED'){
                    			_.forEach(executedOrder.votes, function (item, idx) {
                    				executedOrder.votes[idx].vote = 'NO_VOTE';
                                })
                                executedOrder.yesVotes = 0;
                    			executedOrder.noVotes = 0;
                    			executedOrder.abstentionVotes = 0;
                    			executedOrder.missingVotes = 0;
                    			executedOrder.changeStateMotive = undefined;
                    			executedOrder.voteStateUpdated = undefined;
                                
                            }
                        	executedOrdersUpdate.push(executedOrder);
                        });
                    }
                    
                    if(type == 'NOMINAL' && !error && self.executedOrderNom && self.executedOrderNom.length > 1) {
                    	_.forEach(self.executedOrderNom, function (executedOrder) {
                    		var idxExecutedOrder = $linq(vm.sessionComponent.session.executedOrder).indexOf("x => x.id == " + executedOrder.id);
                        	var numVotesNormal = 0;
                        	var yesVotesNormal = $linq(self.executedOrderVotes.votes).count("x => x.vote == 'YES' && x.type == 'ORDINARY'");
                            var noVotesNormal = $linq(self.executedOrderVotes.votes).count("x => x.vote == 'NO' && x.type == 'ORDINARY'");
                            var abstentionVotesNormal = $linq(self.executedOrderVotes.votes).count("x => x.vote == 'ABSTENTION' && x.type == 'ORDINARY'");
                            var noPresentVotesNormal = $linq(self.executedOrderVotes.votes).count("x => x.vote == 'MISSING' && x.type == 'ORDINARY'");
                            executedOrder = angular.copy(vm.sessionComponent.session.executedOrder[idxExecutedOrder]);
                            executedOrder.voteState = getVoteState(numVotesTotalAuxNominal, yesVotesNormal, noVotesNormal, abstentionVotesNormal, noPresentVotesNormal, executedOrder.requiredVoteState);
                            
                            _.forEach(executedOrder.votes, function (vote, index) {
                                var idxVote = $linq(self.executedOrderVotes.votes).indexOf("x => x.id == " + vote.id);
                                if (idxVote != -1) {
                                	executedOrder.votes[index].vote = self.executedOrderVotes.votes[idxVote].vote;
                                }
                            });
                            
                            if(executedOrder.voteState == 'REFUSED'){
                    			_.forEach(executedOrder.votes, function (item, idx) {
                    				executedOrder.votes[idx].vote = 'NO_VOTE';
                                })
                                executedOrder.yesVotes = 0;
                    			executedOrder.noVotes = 0;
                    			executedOrder.abstentionVotes = 0;
                    			executedOrder.missingVotes = 0;
                    			executedOrder.changeStateMotive = undefined;
                    			executedOrder.voteStateUpdated = undefined;
                            }
                        	executedOrdersUpdate.push(executedOrder);
                        });
                    }
                    

                    if(!error) {
                    	RestService.update('./api/sec/session_executed_order/globalVote', executedOrdersUpdate)
                            .then(function (data) {
                            	
                            	_.forEach(data, function (executedOrder) {
                            		var idxExecutedOrder = $linq(vm.sessionComponent.session.executedOrder).indexOf("x => x.id == " + executedOrder.id);
                            		vm.sessionComponent.session.executedOrder[idxExecutedOrder] = angular.copy(executedOrder);
                            		if(type == 'SECRET'){
                            			var idxModalExecutedOrder = $linq(self.executedOrderSec).indexOf("x => x.id == " + executedOrder.id);
                            			self.executedOrderSec[idxModalExecutedOrder] = angular.copy(executedOrder);
                            		}
                            		if(type == 'NOMINAL'){
                            			var idxModalExecutedOrder = $linq(self.executedOrderNom).indexOf("x => x.id == " + executedOrder.id);
                            			self.executedOrderNom[idxModalExecutedOrder] = angular.copy(executedOrder);
                            		}
                            	});
                            	
                            	$rootScope.$broadcast('sessionPointOrderUpdated', {origin: 'executedOrder'});
                            	
                            	if(type == 'SECRET'){
                            		var countOrdSec = $linq(vm.sessionComponent.session.executedOrder).count("x => (x.votationType == 'ORDINARY' || x.votationType == 'SECRET') && x.type.vote && x.voteState != 'REFUSED'");
                            		if(countOrdSec < 1) {
                    	        		DialogsFactory.notify('global.sec.literals.retirePointsAllMissingOrAbstentionBody', $filter('translate')('global.sec.literals.retirePoint'));
                    	        		self.executedOrderSec = undefined;
                    	        	}
                            	}
                            	
                            	if(type == 'NOMINAL'){
                    	        	var countNominal = $linq(vm.sessionComponent.session.executedOrder).count("x => x.votationType == 'NOMINAL' && x.type.vote && x.voteState != 'REFUSED'");
                    	        	if(countNominal < 1) {
                    	        		DialogsFactory.notify('global.sec.literals.retirePointsAllMissingOrAbstentionBody', $filter('translate')('global.sec.literals.retirePoint'));
                    	        		self.executedOrderNom = undefined;
                    	        	}
                            	}
                            	
                        		if(!self.executedOrderSec && !self.executedOrderNom) {
                        			modal.close();
                        		}
                            }).catch(function (error) {
                        });
                    }
                }
	        	AnnexaModalFactory.showModal('globalVoteModal', modal);
	        }

	        var updateStartCancelSession = function (session) {
                vm.sessionComponent.session.realStartSessionDate = session.realStartSessionDate;
                vm.sessionComponent.session.startSessionUser = session.startSessionUser;
                vm.sessionComponent.session.state = session.state;
                if(vm.sessionComponent.session.executedOrder && vm.sessionComponent.session.executedOrder.length > 0){
                    _.forEach(vm.sessionComponent.session.executedOrder, function(exOrder){
                        if(session.executedOrder && session.executedOrder.length > 0) {
                            var exOrderAux = $linq(session.executedOrder).singleOrDefault(undefined, "x => x.id == " + exOrder.id);
                            if (exOrderAux) {
                                exOrder.votes = exOrderAux.votes;
                            }
                        }else{
                            if(exOrder.votes){
                                exOrder.votes.length = 0;
                            }else{
                                exOrder.votes = [];
                            }
                        }
                    });

                }

                HeaderService.changeState($state.current, true);
                $rootScope.$broadcast('sessionModifyStatusOfSession');
            }
            
            vm.generateCertificates = function(){
            	if(vm.sessionComponent.session.realStartSessionDate && vm.sessionComponent.session.realFinishSessionDate) {
            		var title = 'global.sec.literals.confirmGenerateCertificatesTitle';
            		var body = 'global.sec.literals.confirmGenerateCertificatesBody';
            		var existError = 'global.sec.literals.confirmGenerateCertificatesExistErrorBody';
            		if(vm.sessionComponent && vm.sessionComponent.session && vm.sessionComponent.session.organ && vm.sessionComponent.session.organ.resolutionType == 'OPINION'){
            			title = 'global.sec.literals.confirmGenerateDictamenTitle';
                		body = 'global.sec.literals.confirmGenerateDictamenBody';
                		existError = 'global.sec.literals.confirmGenerateDictamenExistErrorBody';
            		}
            		DialogsFactory.confirm(title, body).then(function (dataAux) {
            			var documentType = 'SCR';
            			if(vm.sessionComponent.session.organ.resolutionType == 'OPINION'){
            				documentType = 'SDI';
            			}
            			SecFactory.createDocumentModal(documentType, null, 'session', vm.sessionComponent.session, true)
                        .then(function(data) {
                            var selfData = data;
                            $rootScope.showLoadingdivSignin = true;
                            $http({
                                url: './api/sec/session/' + vm.sessionComponent.session.id + '/certificates',
                                method: 'POST', 
                                data: JSOG.encode(selfData.request)
                            }).then(function(data) {
                            	var acAux = vm.sessionComponent.session.archiveClassificationAux;
                                vm.sessionComponent.session = JSOG.decode(data.data);
                            	vm.sessionComponent.session.archiveClassificationAux = acAux;
                                selfData.modal.close();
                            	$rootScope.showLoadingdivSignin = false;
                                $rootScope.$broadcast('sessionGenerateCertificates', {});
                                if($rootScope.app && $rootScope.app.configuration && $rootScope.app.configuration.generate_certificates_background && $rootScope.app.configuration.generate_certificates_background.active == true){
	                                $rootScope.$broadcast('generateCertificatesAdded', {sessionId: s.id});
	                                DialogsFactory.notify($filter('translate')('global.literals.sendCertificatesOK'), $filter('translate')('DIALOGS_NOTIFICATION'));
                                }
                            }).catch(function (error) {
                            	$rootScope.showLoadingdivSignin = false;
                            	selfData.modal.close();
                            	if(error && error.data && error.data.message == 'Exist document'){
                            		DialogsFactory.confirm(title, existError).then(function (dataAux) {
                            			vm.updateCertificates();
                            		}).catch(function(error) {
                                        $state.reload();
                                    });
                            	}else if(error && error.data && error.data.code && error.data.code.toUpperCase() == 'Template Exception'.toUpperCase()){
									var msg = $filter('translate')('global.tram.errors.processTemplate')+((error.data.message)?'<br/><br/>'+error.data.message:'');
									DialogsFactory.error(msg, $filter('translate')('DIALOGS_ERROR_MSG'));
                            	}else if(error && error.data && error.data.message && error.data.message.toUpperCase().startsWith("We are working with this session.".toUpperCase())){
									DialogsFactory.error($filter('translate')('global.literals.errorSessionWorking'), $filter('translate')('DIALOGS_ERROR_MSG'));
    							}else{
				                	DialogsFactory.error($filter('translate')('global.sec.errors.cantGenerateCertificates'));
                            	}
                            });
                        }).catch(function(error) {
                            //Empty;
                        })
                    }).catch(function (data) {
                    	//Empty;
		            });
                } else {
                	
                }
            }

            vm.createAct = function(){
            	if(vm.sessionComponent.session.realStartSessionDate && vm.sessionComponent.session.realFinishSessionDate) {
                    DialogsFactory.confirm('global.sec.literals.confirmGenerateActTitle', 'global.sec.literals.confirmGenerateActBody').then(function (dataAux) {
                    	SecFactory.createDocumentModal('SAC', null, 'session', vm.sessionComponent.session, true)
                        .then(function(data) {
                            var selfData = data;
                            $http({
                                url: './api/sec/session/' + vm.sessionComponent.session.id + '/act',
                                method: 'POST', 
                                data: JSOG.encode(selfData.request)
                            }).then(function(data) {
                            	var acAux = vm.sessionComponent.session.archiveClassificationAux;
                                vm.sessionComponent.session = JSOG.decode(data.data);
                                vm.sessionComponent.session.archiveClassificationAux = acAux;
                            	selfData.modal.close();
                                $rootScope.$broadcast('sessionGenerateAct', {})
                            }).catch(function (error) {
                            	selfData.modal.close();
                            	if(error && error.data && error.data.message == 'Exist document'){
                            		DialogsFactory.confirm('global.sec.literals.confirmGenerateActTitle', 'global.sec.literals.confirmGenerateActExistErrorBody').then(function (dataAux) {
                            			vm.updateAct();
                            		}).catch(function(error) {
                                        $state.reload();
                                    });
                            	}else if(error && error.data && error.data.code && error.data.code.toUpperCase() == 'Template Exception'.toUpperCase()){
									var msg = $filter('translate')('global.tram.errors.processTemplate')+((error.data.message)?'<br/><br/>'+error.data.message:'');
									DialogsFactory.error(msg, $filter('translate')('DIALOGS_ERROR_MSG'));
                            	}else if(error && error.data && error.data.message && error.data.message.toUpperCase().startsWith("We are working with this session.".toUpperCase())){
									DialogsFactory.error($filter('translate')('global.literals.errorSessionWorking'), $filter('translate')('DIALOGS_ERROR_MSG'));
    							}else{
				                	DialogsFactory.error($filter('translate')('global.sec.errors.cantGenerateAct'));
                            	}
                            });
                        }).catch(function(error) {
                            //Empty;
                        })
                    }).catch(function (data) {
                    	//Empty;
		            });
                } else {
                	DialogsFactory.error($filter('translate')('global.sec.errors.cantGenerateAct'));
                }
            }
            
            vm.createAgreements = function(){
        	    if(vm.sessionComponent.session.realStartSessionDate && vm.sessionComponent.session.realFinishSessionDate) {
                    DialogsFactory.confirm('global.sec.literals.confirmGenerateAgreementsTitle', 'global.sec.literals.confirmGenerateAgreementsBody').then(function (dataAux) {
                    	SecFactory.createDocumentModal('SPA', null, 'session', vm.sessionComponent.session, true)
                        .then(function(data) {
                            var selfData = data;
                            $http({
                                url: './api/sec/session/' + vm.sessionComponent.session.id + '/preact',
                                method: 'POST', 
                                data: JSOG.encode(selfData.request)
                            }).then(function(data) {
                            	var acAux = vm.sessionComponent.session.archiveClassificationAux;
                                vm.sessionComponent.session = JSOG.decode(data.data);
                            	vm.sessionComponent.session.archiveClassificationAux = acAux;
                            	selfData.modal.close();
                                $rootScope.$broadcast('sessionGenerateAgreements', {})
                            }).catch(function (error) {
                            	selfData.modal.close();
                            	if(error && error.data && error.data.message == 'Exist document'){
                            		DialogsFactory.confirm('global.sec.literals.confirmGenerateAgreementsTitle', 'global.sec.literals.confirmGeneratePreActExistErrorBody').then(function (dataAux) {
                            			vm.updateAgreements();
                            		}).catch(function(error) {
                                        $state.reload();
                                    });
                            	}else if(error && error.data && error.data.code && error.data.code.toUpperCase() == 'Template Exception'.toUpperCase()){
									var msg = $filter('translate')('global.tram.errors.processTemplate')+((error.data.message)?'<br/><br/>'+error.data.message:'');
									DialogsFactory.error(msg, $filter('translate')('DIALOGS_ERROR_MSG'));
                            	}else if(error && error.data && error.data.message && error.data.message.toUpperCase().startsWith("We are working with this session.".toUpperCase())){
									DialogsFactory.error($filter('translate')('global.literals.errorSessionWorking'), $filter('translate')('DIALOGS_ERROR_MSG'));
    							}else{
				                	DialogsFactory.error($filter('translate')('global.sec.errors.cantGenerateAgreements'));
                            	}
                            });
                        }).catch(function(error) {
                            //Empty;
                        })
                    }).catch(function (data) {
                    	//Empty;
		            });
                } else {
                	DialogsFactory.error($filter('translate')('global.sec.errors.cantGenerateAgreements'));
                }
            }
            
            vm.getCertificatesName = function(update){
            	if(vm.sessionComponent && vm.sessionComponent.session && vm.sessionComponent.session.organ && vm.sessionComponent.session.organ.resolutionType == 'OPINION'){
            		if(update){
            			return $filter('translate')('global.sec.literals.updateOpinionDocuments');
            		}else{
            			return $filter('translate')('global.sec.literals.generateOpinionDocuments');
            		}
            	}else{
            		if(update){
            			return $filter('translate')('global.sec.literals.updateCertificates');
            		}else{
            			return $filter('translate')('global.sec.literals.generateCertificates');
            		}
            	}
            }
            vm.updateAgreements = function(){
            	DialogsFactory.confirm('global.sec.literals.updateAgreements', 'global.sec.literals.confirmUpdateAgreementsBody').then(function (dataAux) {
           	     	if(vm.sessionComponent.haveAgreementsDocumentSigned){
	           	     	DialogsFactory.confirm('global.sec.literals.updateAgreements', 'global.sec.literals.confirmUpdateSignedAgreementsBody').then(function (dataAux) {
               	     	 	$http({
    	                        url: './api/sec/session/' + vm.sessionComponent.session.id + '/preact/update',
    	                        method: 'POST' 
    	             		}).then(function(data) {
    	             			var s = JSOG.decode(data.data);
     	                 	    vm.sessionComponent.session.documents = s.documents;
     	                 	    vm.sessionComponent.session.working = s.working;
     	                 	    vm.sessionComponent.haveAgreementsDocumentSigned = false;	                 	    
    	                 	    $rootScope.$broadcast('sessionGenerateAgreements', {})
    	             		}).catch(function (error) {
    	             			if(error && error.data && error.data.code && error.data.code.toUpperCase() == 'Template Exception'.toUpperCase()){
									var msg = $filter('translate')('global.tram.errors.processTemplate')+((error.data.message)?'<br/><br/>'+error.data.message:'');
									DialogsFactory.error(msg, $filter('translate')('DIALOGS_ERROR_MSG'));
    	             			}else if(error && error.data && error.data.message && error.data.message.toUpperCase().startsWith("We are working with this session.".toUpperCase())){
									DialogsFactory.error($filter('translate')('global.literals.errorSessionWorking'), $filter('translate')('DIALOGS_ERROR_MSG'));
    							}else{
				                	DialogsFactory.error($filter('translate')('global.sec.errors.cantGenerateAgreements'));
								}
    	             		});           	     		
	               	    }).catch(function (data) {
	                   	//Empty;
	    	            });
           	     	}else{
	           	     	$http({
	                        url: './api/sec/session/' + vm.sessionComponent.session.id + '/preact/update',
	                        method: 'POST' 
	             		}).then(function(data) {
	                 	    var s = JSOG.decode(data.data);
	                 	    vm.sessionComponent.session.documents = s.documents;
	                 	    vm.sessionComponent.session.working = s.working;
	                 	    vm.sessionComponent.haveAgreementsDocumentSigned = false;	                 	    
	                        $rootScope.$broadcast('sessionGenerateAgreements', {})
	             		}).catch(function (error) {
	             			if(error && error.data && error.data.code && error.data.code.toUpperCase() == 'Template Exception'.toUpperCase()){
								var msg = $filter('translate')('global.tram.errors.processTemplate')+((error.data.message)?'<br/><br/>'+error.data.message:'');
								DialogsFactory.error(msg, $filter('translate')('DIALOGS_ERROR_MSG'));
	             			}else if(error && error.data && error.data.message && error.data.message.toUpperCase().startsWith("We are working with this session.".toUpperCase())){
								DialogsFactory.error($filter('translate')('global.literals.errorSessionWorking'), $filter('translate')('DIALOGS_ERROR_MSG'));
							}else{
			                	DialogsFactory.error($filter('translate')('global.sec.errors.cantGenerateAgreements'));
							}
	             		});           	     		
           	     	}
               }).catch(function (data) {
               	//Empty;
	            });
            }
             
            vm.updateAct = function(){
            	DialogsFactory.confirm('global.sec.literals.updateAct', 'global.sec.literals.confirmUpdateActBody').then(function (dataAux) {
            		if(vm.sessionComponent.haveActDocumentSigned){
            			DialogsFactory.confirm('global.sec.literals.updateAct', 'global.sec.literals.confirmUpdateSignedActBody').then(function (dataAux) {
                			$http({
                                url: './api/sec/session/' + vm.sessionComponent.session.id + '/act/update',
                                method: 'POST' 
                            }).then(function(data) {
                            	var s = JSOG.decode(data.data);
     	                 	    vm.sessionComponent.session.documents = s.documents;
     	                 	    vm.sessionComponent.session.working = s.working;
                         	   vm.sessionComponent.haveActDocumentSigned = false; 
                               $rootScope.$broadcast('sessionGenerateAct', {})
                            }).catch(function (error) {
                            	if(error && error.data && error.data.message && error.data.message.toUpperCase().startsWith("We are working with this session.".toUpperCase())){
                            		DialogsFactory.error($filter('translate')('global.literals.errorSessionWorking'), $filter('translate')('DIALOGS_ERROR_MSG'));
                            	}else{
                            		DialogsFactory.error($filter('translate')('global.sec.errors.cantGenerateAct'));
                            	}
                            });            			
                       }).catch(function (data) {
                       	//Empty;
        	            });
            		}else{
            			$http({
                            url: './api/sec/session/' + vm.sessionComponent.session.id + '/act/update',
                            method: 'POST' 
                        }).then(function(data) {
                        	var s = JSOG.decode(data.data);
 	                 	    vm.sessionComponent.session.documents = s.documents;
 	                 	    vm.sessionComponent.session.working = s.working;
 	                 	    vm.sessionComponent.haveActDocumentSigned = false; 
                            $rootScope.$broadcast('sessionGenerateAct', {})
                        }).catch(function (error) {
                        	if(error && error.data && error.data.code && error.data.code.toUpperCase() == 'Template Exception'.toUpperCase()){
								var msg = $filter('translate')('global.tram.errors.processTemplate')+((error.data.message)?'<br/><br/>'+error.data.message:'');
								DialogsFactory.error(msg, $filter('translate')('DIALOGS_ERROR_MSG'));
                        	}else if(error && error.data && error.data.message && error.data.message.toUpperCase().startsWith("We are working with this session.".toUpperCase())){
								DialogsFactory.error($filter('translate')('global.literals.errorSessionWorking'), $filter('translate')('DIALOGS_ERROR_MSG'));
							}else{
				                DialogsFactory.error($filter('translate')('global.sec.errors.cantGenerateAct'));
							}
                        });            			
            		}
               }).catch(function (data) {
               	//Empty;
	            });
            }
            
            vm.updateCertificates = function(){
            	var title = 'global.sec.literals.updateCertificates';
        		var body = 'global.sec.literals.confirmUpdateCertificatesBody';
        		if(vm.sessionComponent && vm.sessionComponent.session && vm.sessionComponent.session.organ && vm.sessionComponent.session.organ.resolutionType == 'OPINION'){
        			title = 'global.sec.literals.updateOpinionDocuments';
            		body = 'global.sec.literals.confirmUpdateDictamenBody';
        		}
        		var callToUpdate = function(){
        			var modal = angular.copy(SecModals.updateCertificates);
		            modal.alerts = [];
		            modal.title = title;
		            modal.annexaFormly.model = {};
		            modal.annexaFormly.model.modal_body = {};
		            modal.annexaFormly.options = {};
		            modal.annexaFormly.options.formState = {readOnly: false};
		            modal.annexaFormly.model.modal_body.sessionExecutedOrdersAux = angular.copy($linq(vm.sessionComponent.session.executedOrder).where("x=>x.proposal && x.proposal.id && x.voteState != 'REFUSED' && x.voteState != 'POSTPONED'").toArray())
		            if(modal.annexaFormly.model.modal_body.sessionExecutedOrdersAux && modal.annexaFormly.model.modal_body.sessionExecutedOrdersAux.length > 0){
		            	modal.annexaFormly.model.modal_body.sessionExecutedOrders = {};
		            	_.forEach(modal.annexaFormly.model.modal_body.sessionExecutedOrdersAux, function(val){
		            		if(val.proposal && val.proposal.documents){
		            			var doc = undefined;
		            			if(vm.sessionComponent && vm.sessionComponent.session && vm.sessionComponent.session.organ && vm.sessionComponent.session.organ.resolutionType == 'OPINION'){
		            				doc = $linq(val.proposal.documents).firstOrDefault(undefined,"x => x.proposalDocumentType && x.proposalDocumentType == 'SDI'");
		            			}else{
		            				doc = $linq(val.proposal.documents).firstOrDefault(undefined,"x => x.proposalDocumentType && x.proposalDocumentType == 'SCR'");
		            			}
		            			if(doc){
		            				modal.annexaFormly.model.modal_body.sessionExecutedOrders[val.id] = false;
		            			}else{
		            				modal.annexaFormly.model.modal_body.sessionExecutedOrders[val.id] = true;
		            			}
		            		}else{
		            			modal.annexaFormly.model.modal_body.sessionExecutedOrders[val.id] = false;
		            		}
		            	});
		            }
		            var submitUpdateCertificateFunction = function () {
		            	var updatedIds = $linq(Object.keys(modal.annexaFormly.model.modal_body.sessionExecutedOrders)).where(function(x){
                            if(modal.annexaFormly.model.modal_body.sessionExecutedOrders[x]){
                                return true;
                            }else{
                                return false;
                            }
                        }).toArray();
		            	if(updatedIds && updatedIds.length > 0){
			            	$http({
    	                        url: './api/sec/session/' + vm.sessionComponent.session.id + '/certificates/update/' + CommonService.getParameterList(updatedIds),
    	                        method: 'POST' 
    	             		}).then(function(data) {
    	             		   var s = JSOG.decode(data.data);
    	                 	   vm.sessionComponent.session.documents = s.documents;
    	                 	   vm.sessionComponent.session.working = s.working;
    	                 	   vm.sessionComponent.haveCertificatesDocumentSigned = false;
    	                       $rootScope.$broadcast('sessionGenerateAgreements', {})
    	                       if($rootScope.app && $rootScope.app.configuration && $rootScope.app.configuration.generate_certificates_background && $rootScope.app.configuration.generate_certificates_background.active == true){
	                                $rootScope.$broadcast('generateCertificatesAdded', {sessionId: s.id});
	                                DialogsFactory.notify($filter('translate')('global.literals.sendCertificatesOK'), $filter('translate')('DIALOGS_NOTIFICATION'));
                               }
    	                       modal.close();
    	             		}).catch(function (error) {
    	             			if(error && error.data && error.data.code && error.data.code.toUpperCase() == 'Template Exception'.toUpperCase()){
									var msg = $filter('translate')('global.tram.errors.processTemplate')+((error.data.message)?'<br/><br/>'+error.data.message:'');
									DialogsFactory.error(msg, $filter('translate')('DIALOGS_ERROR_MSG'));
    	             			}else if(error && error.data && error.data.message && error.data.message.toUpperCase().startsWith("We are working with this session.".toUpperCase())){
									DialogsFactory.error($filter('translate')('global.literals.errorSessionWorking'), $filter('translate')('DIALOGS_ERROR_MSG'));
    							}else{
				                	DialogsFactory.error($filter('translate')('global.sec.errors.cantGenerateAgreements'));
								}
    	             			modal.close();
    	             		}); 
		            	}else{
		            		modal.close();
		            	}
            		}
		
		            AnnexaFormlyFactory.showModal("modalRetireSessionPoint", modal, submitUpdateCertificateFunction, false);
        		}
            	
        		DialogsFactory.confirm(title, body).then(function (dataAux) {
        			if(vm.sessionComponent.haveCertificatesDocumentSigned){
	        			var bodyAux = 'global.sec.literals.confirmUpdateSignedCertificatesBody';
	            		if(vm.sessionComponent && vm.sessionComponent.session && vm.sessionComponent.session.organ && vm.sessionComponent.session.organ.resolutionType == 'OPINION'){
	            			bodyAux = 'global.sec.literals.confirmUpdateSignedDictamenBody';
	        			}
	            		DialogsFactory.confirm(title, bodyAux).then(function (dataAux) {
	            			callToUpdate();
	            		}).catch(function (data) {
	                   	//Empty;
	    	            });
        			}else{
        				callToUpdate();
        			}
        		}).catch(function (data) {
                   	//Empty;
   	            });
            }
            
            
            vm.sendOpinionProposal = function(){
            	if(vm.sessionComponent.session.realStartSessionDate && vm.sessionComponent.session.realFinishSessionDate) {
            		if(!vm.sessionComponent.haveCertificatesDocumentSigned){
            			DialogsFactory.error($filter('translate')('global.sec.errors.cantCloseSession_dictamentDocumentNotSigned'));
            		}else{
	            		DialogsFactory.confirm('global.sec.literals.sendOpinion', 'global.sec.literals.confirmSendOpinion')
	                    .then(function(data) {
	                		var modal = angular.copy(SecModals.raiseOpinion);
	                		modal.alerts = [];
		                    modal.languageColumn = Language.getActiveColumn();
	                		modal.data = {
	                				type: 'OPINION',
	                				organ: vm.sessionComponent.session.organ,
	                				proposals: $linq(vm.sessionComponent.session.executedOrder).where("x => x.proposal && x.proposal.state != 'ARCHIVED' && x.voteState != 'REFUSED' && x.voteState != 'POSTPONED'").select("x => x.proposal").toArray()
	                		}
	                		if(modal.data && modal.data.proposals){
	                			modal.data.proposals = angular.copy(modal.data.proposals);
	                			_.forEach(modal.data.proposals, function(prop){
	                				var actions = [];
									var archived = {id:'ARCHIVED',name:$filter('translate')('global.sec.literals.archived')};
									var organToAdd = {id:'ORGAN',name:$filter('translate')('global.sec.literals.raiseTo')};
		                			if(prop && prop.organs){
		                				var actualOrganOrderView = $linq(prop.organs).firstOrDefault(undefined, "x => x.organ.id == "+vm.sessionComponent.session.organ.id);
		                				if(actualOrganOrderView){
		                					var organ = $linq(prop.organs).orderBy("x => x.orderView").firstOrDefault(undefined, "x => x.orderView > "+actualOrganOrderView.orderView);
		                					if(organ && organ.organ){
		                						organToAdd.name = organToAdd.name + " "+organ.organ[vm.languageColumn];
		                						actions.push(organToAdd);
		                						if(!prop.actionToDo){
		                							prop.actionToDo = organToAdd;
		                						}
		                					}
		                				} 
		                			}
		                			actions.push(archived);
		                			if(prop && !prop.actionToDo){
		                				prop.actionToDo = archived;
		                			}
		                			prop.actionsToDo = actions;
	                			});
	                		}
	                		modal.getTitle = function(proposal){
	                			var content = '';
	                			if(proposal && proposal.state){
	                				if(SecFactory.proposalStates && SecFactory.proposalStates.length > 0) {
	                	                var state = $linq(SecFactory.proposalStates).singleOrDefault(undefined, "x => x.id == '" + proposal.state + "'");
	                	                if(state) {
	                	                    content = $filter('translate')(state.name);
	                	                }
	                	            }	
	                			}
	                			return content;
	                		}
							modal.getIcon = function(proposal){
	                			var content = '';
	                			if(proposal && proposal.state){
	                				if(SecFactory.proposalStates && SecFactory.proposalStates.length > 0) {
	                	                var state = $linq(SecFactory.proposalStates).singleOrDefault(undefined, "x => x.id == '" + proposal.state + "'");
	                	                if(state) {
	                	                    content = state.icon + ' ' + state.style;
	                	                }
	                	            }	
	                			}
	                			return content;
							}
							modal.getDossier = function(proposal){
								var content = '';
	                			if(proposal && proposal.createdTransaction && proposal.createdTransaction.dossier &&  proposal.createdTransaction.dossier.dossierNumber){
	                				content = proposal.createdTransaction.dossier.dossierNumber;
	                			}
	                			return content;	
							}
							modal.getNextOrgan = function(proposal){
								var content = '';
	                			if(proposal && proposal.organs){
	                				var actualOrganOrderView = $linq(proposal.organs).firstOrDefault(undefined, "x => x.organ.id == "+vm.sessionComponent.session.organ.id);
	                				if(actualOrganOrderView){
	                					var organ = $linq(proposal.organs).orderBy("x => x.orderView").firstOrDefault(undefined, "x => x.orderView > "+actualOrganOrderView.orderView);
	                					if(organ && organ.organ){
	                						content = organ.organ[vm.languageColumn];
	                					}
	                				}
	                			}
	                			return content;
							}
							
	                		modal.submitFunction = function () {
	                			var proposals = ((this && this.data && this.data.proposals)?this.data.proposals:[]);
	                			var propsActions = {};
	                			if(proposals && proposals.length){
	                				_.forEach(proposals, function(prop){
	                					propsActions[prop.id] = ((prop && prop.actionToDo && prop.actionToDo.id)?prop.actionToDo.id:'ORGAN');
	                				})
	                			}
	                			$http({
	                                url: './api/sec/session/' + vm.sessionComponent.session.id + '/raise_opinion',
	                                method: 'PUT',
	                                data: propsActions 
	                            }).then(function (data) {
	                            	modal.close();
	                                $state.reload();
	                            }).catch(function (error) {
									if(error && error.data && error.data.message && error.data.message == 'Is not valid document from template not found'){
										DialogsFactory.error($filter('translate')('global.documents.definitiveNotificationNoValidError'));
									}else if(error && error.data && error.data.message && error.data.message.toUpperCase().startsWith("We are working with this session.".toUpperCase())){
										DialogsFactory.error($filter('translate')('global.literals.errorSessionWorking'), $filter('translate')('DIALOGS_ERROR_MSG'));
	    							}else{	
	                            		DialogsFactory.error(ErrorFactory.getErrorMessage('session', 'close', error.data ));
									}
	                            });
	                		}
	                		modal.submitLabel = 'global.sec.literals.sendOpinion';
	                		AnnexaModalFactory.showModal('modalInitTransfer', modal);
	                    }).catch(function(error) {
	                        //Empty
	                    });
            		}
                } else {
                	DialogsFactory.error($filter('translate')('global.sec.errors.cantCloseSession_sessionIsNotFinished'));
                }
            }
            
            vm.closeSession = function(){
            	if(vm.sessionComponent.session.realStartSessionDate && vm.sessionComponent.session.realFinishSessionDate) {
            		if(!vm.sessionComponent.haveCertificatesDocument && vm.haveProposalOrDictamenPoints){
            			if(vm.sessionComponent && vm.sessionComponent.session && vm.sessionComponent.session.organ && vm.sessionComponent.session.organ.resolutionType == 'OPINION'){
            				DialogsFactory.error($filter('translate')('global.sec.literals.confirmCloseSessionNoDictamens'));
            			}else{
            				DialogsFactory.error($filter('translate')('global.sec.literals.confirmCloseSessionNoCertificates'));
            			}
            		}else if(!vm.sessionComponent.haveCertificatesDocumentSigned  && vm.haveProposalOrDictamenPoints){
            			if(vm.sessionComponent && vm.sessionComponent.session && vm.sessionComponent.session.organ && vm.sessionComponent.session.organ.resolutionType == 'OPINION'){
            				DialogsFactory.error($filter('translate')('global.sec.literals.confirmCloseSessionNoSignedDictamens'));
            			}else{
            				DialogsFactory.error($filter('translate')('global.sec.literals.confirmCloseSessionNoSignedCertificates'));
            			}
            		}else if(vm.sessionComponent && vm.sessionComponent.session && vm.sessionComponent.session.organ && vm.sessionComponent.session.organ.resolutionType == 'OPINION' && !vm.sessionComponent.isRaiseOpinion && vm.haveProposalOrDictamenPoints){
            			DialogsFactory.error($filter('translate')('global.sec.literals.confirmCloseSessionNoRaiseOpinion'));
            		}else if(vm.sessionComponent && vm.sessionComponent.session && vm.sessionComponent.session.organ && vm.sessionComponent.session.organ.resolutionType != 'OPINION' && !vm.sessionComponent.isTransferedAll && vm.haveProposalOrDictamenPoints){
            			DialogsFactory.error($filter('translate')('global.sec.literals.confirmCloseSessionNoTransfered'));
            		}else if(!vm.sessionComponent.haveActDocument){
            			DialogsFactory.error($filter('translate')('global.sec.literals.confirmCloseSessionNoActDocument'));
            		}else if(!vm.sessionComponent.haveActDocumentSigned){
            			DialogsFactory.error($filter('translate')('global.sec.literals.confirmCloseSessionNoSignedActDocument'));
            		}else if(!vm.sessionComponent.haveAgreementsDocument && $rootScope.app.configuration && $rootScope.app.configuration.secretary_properties && $rootScope.app.configuration.secretary_properties.spa_required == "true"){
            			DialogsFactory.error($filter('translate')('global.sec.literals.confirmCloseSessionNoPreactDocument'));
            		}else if(!vm.sessionComponent.haveAgreementsDocumentSigned && $rootScope.app.configuration && $rootScope.app.configuration.secretary_properties && $rootScope.app.configuration.secretary_properties.spa_required == "true"){
            			DialogsFactory.error($filter('translate')('global.sec.literals.confirmCloseSessionNoSignedPreactDocument'));
            		}else{
            			var literalTitle = 'global.sec.literals.closeSession';
                		DialogsFactory.confirm(literalTitle, 'global.sec.literals.confirmCloseSession')
	                    .then(function(data) {
	                    	if(!vm.sessionComponent.haveAgreementsDocument && !($rootScope.app.configuration && $rootScope.app.configuration.secretary_properties && $rootScope.app.configuration.secretary_properties.spa_required == "true")){
	                    		  DialogsFactory.confirm(literalTitle, 'global.sec.literals.confirmCloseSessionWithoutPreact').then(function(data) {
	                    			 $http({
	     	                            url: './api/sec/session/' + vm.sessionComponent.session.id + '/close',
	     	                            method: 'PUT'
	     	                        }).then(function (data) {
	     	                            $state.go('annexa.sec.sessions');
	     	                        }).catch(function (error) {
	     	                        	if(error && error.data && error.data.message && error.data.message.toUpperCase().startsWith("We are working with this session.".toUpperCase())){
											DialogsFactory.error($filter('translate')('global.literals.errorSessionWorking'), $filter('translate')('DIALOGS_ERROR_MSG'));
	        							}else{
	     	                        		DialogsFactory.error(ErrorFactory.getErrorMessage('session', 'close', error.data ));
	     	                        	}
	     	                        });
	 		                     }).catch(function(error) {
	 		                        //Empty
	 		                     });
	                    	}else{
	                    		$http({
		                            url: './api/sec/session/' + vm.sessionComponent.session.id + '/close',
		                            method: 'PUT'
		                        }).then(function (data) {
		                            $state.go('annexa.sec.sessions');
		                        }).catch(function (error) {
		                        	if(error && error.data && error.data.message && error.data.message.toUpperCase().startsWith("We are working with this session.".toUpperCase())){
										DialogsFactory.error($filter('translate')('global.literals.errorSessionWorking'), $filter('translate')('DIALOGS_ERROR_MSG'));
	    							}else{
										DialogsFactory.error(ErrorFactory.getErrorMessage('session', 'close', error.data ));
		                        	}
		                        });
	                    	}
	                    }).catch(function(error) {
	                        //Empty
	                    });
            		}
                } else {
                	DialogsFactory.error($filter('translate')('global.sec.errors.cantCloseSession_sessionIsNotFinished'));
                }
            }
            
            vm.sendTransferProposal = function(){
            	if(vm.sessionComponent.session.realStartSessionDate && vm.sessionComponent.session.realFinishSessionDate) {
	            	DialogsFactory.confirm('global.sec.literals.sendTransfer', 'global.sec.literals.confirmSendTransfer')
                    .then(function(data) {
						var openModal = function(props){
	                		var modal = angular.copy(SecModals.initTransfers);
							modal.data = {
	                				type: 'AGREEMENT',
	                				organ: vm.sessionComponent.session.organ,
	                				proposals: props
	                		}
	                		modal.alerts = [];
		                    modal.languageColumn = Language.getActiveColumn();
	                		modal.getTitle = function(proposal){
	                			var content = '';
	                			if(proposal && proposal.state){
	                				if(SecFactory.proposalStates && SecFactory.proposalStates.length > 0) {
	                	                var state = $linq(SecFactory.proposalStates).singleOrDefault(undefined, "x => x.id == '" + proposal.state + "'");
	                	                if(state) {
	                	                    content = $filter('translate')(state.name);
	                	                }
	                	            }	
	                			}
	                			return content;
	                		}
							modal.getIcon = function(proposal){
	                			var content = '';
	                			if(proposal && proposal.state){
	                				if(SecFactory.proposalStates && SecFactory.proposalStates.length > 0) {
	                	                var state = $linq(SecFactory.proposalStates).singleOrDefault(undefined, "x => x.id == '" + proposal.state + "'");
	                	                if(state) {
	                	                    content = state.icon + ' ' + state.style;
	                	                }
	                	            }	
	                			}
	                			return content;
							}
							modal.getDossier = function(proposal){
								var content = '';
	                			if(proposal && proposal.createdTransaction && proposal.createdTransaction.dossier &&  proposal.createdTransaction.dossier.dossierNumber){
	                				content = proposal.createdTransaction.dossier.dossierNumber;
	                			}
	                			return content;	
							}
							modal.getNextOrgan = function(proposal){
								var content = '';
	                			if(proposal && proposal.organs){
	                				var actualOrganOrderView = $linq(proposal.organs).firstOrDefault(undefined, "x => x.organ.id == "+vm.sessionComponent.session.organ.id);
	                				if(actualOrganOrderView){
	                					var organ = $linq(proposal.organs).orderBy("x => x.orderView").firstOrDefault(undefined, "x => x.orderView > "+actualOrganOrderView.orderView);
	                					if(organ && organ.organ){
	                						content = organ.organ[vm.languageColumn];
	                					}
	                				}
	                			}
	                			return content;
							}
	                		modal.submitFunction = function () {
								var propsAux = $linq(modal.data.proposals).where("x => x.transferNow").select("x=>x.id").toArray();
								if(propsAux && propsAux.length > 0){
									var callToTransfer = function(){
			                			$http({
			                                url: './api/sec/session/' + vm.sessionComponent.session.id + '/transfers/'+CommonService.getParameterList(propsAux),
			                                method: 'POST'
			                            }).then(function (data) {
			                                modal.close();
			                                $state.reload();
			                            }).catch(function (error) {
			                            	modal.close();
											if(error && error.data && error.data.message && error.data.message == 'Is not valid document from template not found'){
												DialogsFactory.error($filter('translate')('global.documents.definitiveNotificationNoValidError'));
											}else if(error && error.data && error.data.message && error.data.message.toUpperCase().startsWith("We are working with this session.".toUpperCase())){
												DialogsFactory.error($filter('translate')('global.literals.errorSessionWorking'), $filter('translate')('DIALOGS_ERROR_MSG'));
			    							}else{
				                            	DialogsFactory.error(ErrorFactory.getErrorMessage('session', 'transfers', error.data));
											}
			                            });
									}
									var showDossierSonsMessage = false;
									var messageDossierSons = $filter('translate')('global.sec.literals.confirmSendTransferClosedDossierSons1')+'<br/>';
									_.forEach(modal.data.proposals, function(mdp){
										if(_.contains(propsAux, mdp.id) && mdp.workWithDossierSons === true && mdp.closedDossierSons && mdp.closedDossierSons.length > 0){
											showDossierSonsMessage = true;
											_.forEach(mdp.closedDossierSons, function(mdpdossier){
												messageDossierSons += mdpdossier+'<br/>';
											});
										}
									});
									if(showDossierSonsMessage){
										messageDossierSons += $filter('translate')('global.sec.literals.confirmSendTransferClosedDossierSons2');
										DialogsFactory.confirm('global.sec.literals.sendTransfer', messageDossierSons).then(function(data) {
											callToTransfer();
										}).catch(function(error) {
					                        //Empty
					                    });
									}else{
										callToTransfer();
									}
								}else{
									if(!modal.alerts){
										modal.alerts = [];
									}
									modal.alerts.push({msg:$filter('translate')('global.sec.errors.cantTransferCertificatesNoProposals')});
								}
	                		}
	                		
	                		modal.submitLabel = 'global.sec.literals.sendTransfer';
	                        AnnexaModalFactory.showModal('modalInitTransfer', modal);
						}
						
						var props = $linq(vm.sessionComponent.session.executedOrder).where(function(x){
								var ok = false;
								if(x.proposal && x.voteState != 'REFUSED' && x.voteState != 'POSTPONED' && (!x.proposal.notificationTransaction || !x.proposal.notificationTransaction.id)){
									if(x.type && !x.type.vote){
										ok = true;
									}else if(x.type && x.type.vote && x.voteState != 'NO_VOTE'){
										ok = true;
									}
								}
								return  ok;
        					}
						).select("x => x.proposal").toArray();
						props = angular.copy(props);
						if(props && props.length > 0){
							SecFactory.haveSignedCertificate($linq(props).select("x => x.id").toArray()).then(function (data) {
								var haveSignedCertificate = ((data)?JSOG.decode(data):undefined);
								_.forEach(props, function(prop){
									if(haveSignedCertificate && haveSignedCertificate[prop.id]){
										prop.transferNow = true;
									}else{
										prop.transferNow = false;
									}
								});
								openModal(props);
							}).catch(function (error) {
								openModal(props);
							});
							
						}else{
							openModal([]);
						}
                    }).catch(function(error) {
                        //Empty
                    });
                } else {
                	DialogsFactory.error($filter('translate')('global.sec.errors.cantCloseSession_sessionIsNotFinished'));
                }
            }
            
            vm.restartSession = function () {
            	if(vm.sessionComponent && vm.sessionComponent.session && vm.sessionComponent.session.state == 'EXECUTED') {
	                	
	                var self = this;
	                var msg = 'global.sec.literals.restartSessionBody';
	                
	                if (vm.sessionComponent.session.sessionTransferDate && vm.sessionComponent.session.organ && vm.sessionComponent.session.organ.resolutionType == 'AGREEMENT') {
	                	msg = 'global.sec.literals.restartSessionBodySessionTransferDate';
	                }
	
	                DialogsFactory.confirm('global.sec.literals.restartSession', msg, 'zMax3', 'zMax')
	                    .then(function (data) {
	    	                $http({
	                            url: './api/sec/session/' + vm.sessionComponent.session.id + '/restart',
	                            method: 'PUT'
	                        }).then(function (data) {
	                            $state.reload();
	                        }).catch(function (error) {
	                        	if(error && error.data && error.data.message && error.data.message.toUpperCase().startsWith("We are working with this session.".toUpperCase())){
									DialogsFactory.error($filter('translate')('global.literals.errorSessionWorking'), $filter('translate')('DIALOGS_ERROR_MSG'));
	                        	}else{
	                        		DialogsFactory.error(ErrorFactory.getErrorMessage('session', 'reopenSession', error.data));
	                        	}
	                        });
	                    }).catch(function (data) {
	                    //Empty
	                });
            	}
            }
            
            vm.reopenSession = function () {
            	if(vm.sessionComponent.session.state == 'FINISHED') {
	                	
	                var self = this;
	
	                DialogsFactory.confirm('global.sec.literals.reopenSession', 'global.sec.literals.reopenSessionBody', 'zMax3', 'zMax')
	                    .then(function (data) {
	    	                $http({
	                            url: './api/sec/session/' + vm.sessionComponent.session.id + '/reopen',
	                            method: 'PUT'
	                        }).then(function (data) {
	                            $state.reload();
	                        }).catch(function (error) {
		                        if(error && error.data && error.data.message && error.data.message.toUpperCase().startsWith("We are working with this session.".toUpperCase())){
									DialogsFactory.error($filter('translate')('global.literals.errorSessionWorking'), $filter('translate')('DIALOGS_ERROR_MSG'));
		                        }else{
		                        	DialogsFactory.error(ErrorFactory.getErrorMessage('session', 'reopenSession', error.data));
		                        }
	                        });
	                    }).catch(function (data) {
	                    //Empty
	                });
            	}
            }
            
            $scope.$on('sessionPointOrderAdded', function(event, args) {
                if(args.origin && args.origin == 'executedOrder') {
                	vm.executedOrder = CommonService.getNestableData(angular.copy(vm.sessionComponent.session.executedOrder), 'executedOrder', 'orderView');
                	angular.forEach(vm.executedOrder, function(value) {
                		vm.addExecutedOrderCollapsed(value);
                	});
                	vm.haveProposalOrDictamenPoints = false;
                    vm.canVoteGroup = false;
                    if(vm.sessionComponent.session.executedOrder && vm.sessionComponent.session.executedOrder.length > 0){
    	                if(!vm.haveProposalOrDictamenPoints){
    	                	vm.haveProposalOrDictamenPoints = $linq(vm.sessionComponent.session.executedOrder).count("x => x.type.type == 'PROPOSAL' && x.voteState && x.voteState != 'REFUSED' && x.voteState != 'POSTPONED'") > 0;
    	                }
    	                if(!vm.haveProposalOrDictamenPoints){
    	                	vm.haveProposalOrDictamenPoints = $linq(vm.sessionComponent.session.executedOrder).count("x => x.type.type == 'OPINION' && x.voteState && x.voteState != 'REFUSED' && x.voteState != 'POSTPONED'") > 0;	
    	                }
    	                if(!vm.canVoteGroup && vm.sessionComponent.session.organ.globalVote){
    	                	vm.canVoteGroup = vm.canVoteGroupFunction();
    	                }
                    }
                }
            });
            
            
            $scope.$on('sessionPointOrderDeleted', function(event, args) {
                if(args.origin && args.origin == 'executedOrder') {
                	vm.executedOrder = CommonService.getNestableData(angular.copy(vm.sessionComponent.session.executedOrder), 'executedOrder', 'orderView');
                	vm.haveProposalOrDictamenPoints = false;
                    vm.canVoteGroup = false;
                    if(vm.sessionComponent.session.executedOrder && vm.sessionComponent.session.executedOrder.length > 0){
    	                if(!vm.haveProposalOrDictamenPoints){
    	                	vm.haveProposalOrDictamenPoints = $linq(vm.sessionComponent.session.executedOrder).count("x => x.type.type == 'PROPOSAL' && x.voteState && x.voteState != 'REFUSED' && x.voteState != 'POSTPONED'") > 0;
    	                }
    	                if(!vm.haveProposalOrDictamenPoints){
    	                	vm.haveProposalOrDictamenPoints = $linq(vm.sessionComponent.session.executedOrder).count("x => x.type.type == 'OPINION' && x.voteState && x.voteState != 'REFUSED' && x.voteState != 'POSTPONED'") > 0;	
    	                }
    	                if(!vm.canVoteGroup && vm.sessionComponent.session.organ.globalVote){
    	                	vm.canVoteGroup = vm.canVoteGroupFunction();
    	                }
                    	$rootScope.$broadcast('updateSessionBoxes', { })
                    }
                }
            });
            

            $scope.$on('sessionPointOrderUpdated', function(event, args) {
                if(args.origin && args.origin == 'executedOrder') {
                	vm.executedOrder = CommonService.getNestableData(angular.copy(vm.sessionComponent.session.executedOrder), 'executedOrder', 'orderView');
                	vm.haveProposalOrDictamenPoints = false;
                    vm.canVoteGroup = false;
                    if(vm.sessionComponent.session.executedOrder && vm.sessionComponent.session.executedOrder.length > 0){
    	                if(!vm.haveProposalOrDictamenPoints){
    	                	vm.haveProposalOrDictamenPoints = $linq(vm.sessionComponent.session.executedOrder).count("x => x.type.type == 'PROPOSAL' && x.voteState && x.voteState != 'REFUSED' && x.voteState != 'POSTPONED'") > 0;
    	                }
    	                if(!vm.haveProposalOrDictamenPoints){
    	                	vm.haveProposalOrDictamenPoints = $linq(vm.sessionComponent.session.executedOrder).count("x => x.type.type == 'OPINION' && x.voteState && x.voteState != 'REFUSED' && x.voteState != 'POSTPONED'") > 0;	
    	                }
    	                if(!vm.canVoteGroup && vm.sessionComponent.session.organ.globalVote){
    	                	vm.canVoteGroup = vm.canVoteGroupFunction();
    	                }
                    }
                	if(args.point && args.modal){
                		vm.seePoint(args.point, args.modal);
                	}
                }
            });
            
            $scope.$on('sendConvene', function(event, args) {
              	vm.executedOrder = CommonService.getNestableData(angular.copy(vm.sessionComponent.session.executedOrder), 'executedOrder', 'orderView');
            });
            
            vm.initExecutedOrderCollapsed = function(value) {
                if(value.executedOrder && value.executedOrder.id) {
                    vm.executedOrderCollapsed.push({id: value.executedOrder.id, collapsed: true});
                    if(value.nodes && value.nodes.length > 0){
                        angular.forEach(value.nodes, function(node) {
                            vm.initExecutedOrderCollapsed(node);
                        });
                    }
                }
            }
            
            vm.addExecutedOrderCollapsed = function(value) {
                if(!$linq(vm.executedOrderCollapsed).contains(value.executedOrder, "(x, y) => x.id == y")) {
                    vm.executedOrderCollapsed.push({id: value.executedOrder.id, collapsed: true});
                }
                if(value.nodes && value.nodes.length > 0){
                    angular.forEach(value.nodes, function(node) {
                        vm.addExecutedOrderCollapsed(node);
                    });
                }
            }
            
            vm.isCollapsed = function(id) {
                return $linq(vm.executedOrderCollapsed).firstOrDefault(undefined,"x => x.id == "+id).collapsed;
            }
            
            vm.toggleNode = function(id, collapsed){
                $linq(vm.executedOrderCollapsed).firstOrDefault(undefined,"x => x.id == "+id).collapsed = collapsed;
            }
            
            vm.toggleNodeAll = function(){
            	vm.collapsedAll = !vm.collapsedAll;
            	angular.forEach(vm.executedOrderCollapsed, function(item) {
                    item.collapsed = vm.collapsedAll;
                });
            }
            
            vm.canVoteGroupFunction = function() {
	        	var countOrdSec = $linq(vm.sessionComponent.session.executedOrder).count("x => (x.votationType == 'ORDINARY' || x.votationType == 'SECRET') && x.type.vote && x.voteState != 'REFUSED'");
	        	var countNominal = $linq(vm.sessionComponent.session.executedOrder).count("x => x.votationType == 'NOMINAL' && x.type.vote && x.voteState != 'REFUSED'");
            	return countOrdSec > 1 || countNominal > 1;
            }
           
            this.$onInit = function(){
                vm.haveProposalOrDictamenPoints = false;
                vm.canVoteGroup = false;
                vm.collapsedAll = true;
                if(vm.sessionComponent.session.executedOrder && vm.sessionComponent.session.executedOrder.length > 0){
	                if(!vm.haveProposalOrDictamenPoints){
	                	vm.haveProposalOrDictamenPoints = $linq(vm.sessionComponent.session.executedOrder).count("x => x.type.type == 'PROPOSAL' && x.voteState && x.voteState != 'REFUSED' && x.voteState != 'POSTPONED'") > 0;
	                }
	                if(!vm.haveProposalOrDictamenPoints){
	                	vm.haveProposalOrDictamenPoints = $linq(vm.sessionComponent.session.executedOrder).count("x => x.type.type == 'OPINION' && x.voteState && x.voteState != 'REFUSED' && x.voteState != 'POSTPONED'") > 0;	
	                }
	                if(!vm.canVoteGroup && vm.sessionComponent.session.organ.globalVote){
	                	vm.canVoteGroup = vm.canVoteGroupFunction();
	                }
                }
            	vm.executedOrder = CommonService.getNestableData(angular.copy(vm.sessionComponent.session.executedOrder), 'executedOrder', 'orderView');
                
                vm.executedOrderCollapsed = [];
                
                angular.forEach(vm.executedOrder, function(value) {
                    vm.initExecutedOrderCollapsed(value);
                });
            	
            	vm.treeOptions = {
    	            beforeDrop: function(e){
    	            	var deferred = $q.defer();
    	            	var destValueOK = true;
    	            	var destValue = e.dest.nodesScope.node ? e.dest.nodesScope.node.executedOrder: null;
    	                if(destValue != null && destValue != undefined && destValue.type && destValue.type.type != 'GROUP') {
    	                	destValueOK = false;
    	                }
    	            	if(!destValueOK){
    	            		DialogsFactory.error($filter('translate')('global.sec.literals.nodeParentIsNotAGroup'));
    	                	deferred.reject(false);
    	            	}else{
    	            		if(
    	            				(!destValue && e.source.nodeScope && e.source.nodeScope.$modelValue && e.source.nodeScope.$modelValue.executedOrder && (!e.source.nodeScope.$modelValue.executedOrder.parent || !e.source.nodeScope.$modelValue.executedOrder.parent.id)) 
    	            				|| (destValue && e.source.nodeScope && e.source.nodeScope.$modelValue && e.source.nodeScope.$modelValue.executedOrder && e.source.nodeScope.$modelValue.executedOrder.parent && destValue.id == e.source.nodeScope.$modelValue.executedOrder.parent.id)
    	            		){
    	            			if(e.dest.index == e.source.index){
    	            				destValueOK = false;
    	            			}
    	            		}
    	            		if(!destValueOK){
    	            			deferred.reject(false);
    	            		}else{
    	            			DialogsFactory.confirm('global.sec.literals.movePoint', 'global.sec.literals.confirmmovePoint').then(function (dataAux) {
    	    	            		var sourceValue = e.source.nodeScope.$modelValue.executedOrder;
    	        	                var sourceParentValue = null;
    	        	                if( e.source.nodeScope.$modelValue.executedOrder.parent != null &&  e.source.nodeScope.$modelValue.executedOrder.parent != undefined) {
    	        	                    sourceParentValue = e.source.nodeScope.$modelValue.executedOrder.parent.id;
    	        	                }
    	        	                var destValue = e.dest.nodesScope.node ? e.dest.nodesScope.node.executedOrder: null;
    	        	                if(destValue != null && destValue != undefined) {
	        	                        sourceValue.parent = destValue;
    	        	                }else {
    	        	                    sourceValue.parent = null;
    	        	                }
    	        	                sourceValue.orderView = e.dest.index+1;
        	                        savePointTree(sourceValue, deferred);
    	    	                }).catch(function (data) {
    	    	                	deferred.reject(false);
    	    		            });    	            			
    	            		}
    	            	}
    	            	return deferred.promise;
    	            }
    	        };
            	
            }
        }]
    })

    